import React, { useEffect, useState } from 'react'
import SEO from 'src/components/common/SEO'
import FAQSection from 'src/components/common/FAQSection'
import PlatformSection from 'src/components/common/PlatformSection'
import NetworkSection from 'src/components/common/NetworkSection'
import DealSection from 'src/components/common/DealSection'
import Layout from 'src/components/common/Layout'

import { LogoSyncContextProvider } from 'src/components/common/AnnotationContext'
import { createUrl } from 'src/modules/utils/createUrl'
import { useSelector } from 'react-redux'
import { selectAnalyticsParameters } from 'src/modules/reducers/analytics'
import { Link } from 'gatsby'
import CharterPlanCards from 'src/components/common/CharterPlanCards'
import OptimumPlanCards from 'src/components/common/OptimumPlanCards'
import HighlightSection from 'src/components/common/HighlightSection'
import HighlightNBA from 'src/components/common/HighlightNBA'
import HeroSectionNew from 'src/components/common/HeroSectionNew'
import RSNGeolocationBannerNew from 'src/components/common/RSNOnlyBannerNew'
import NetworkSectionNew from 'src/components/common/NetworkSectionNew'
import ChannelSection from 'src/components/common/ChannelSectionNew'
import DealSectionNew from 'src/components/common/DealSectionNew'
import PlatformSectionNew from 'src/components/common/PlatformSectionNew'
import ExtraCTABanner from 'src/components/common/ExtraCTABanner'
import FAQSectionNew from 'src/components/common/FAQSectionNew'
import MatchTicker from 'src/components/common/MatchTicker'
import RedzoneBanner from 'src/components/common/RedzoneBanner'
import DirectvPlanCards from 'src/components/common/DirectvPlanCards'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import FanduelPlanCards from 'src/components/common/FanduelPlanCards'
import ProPlanCards from 'src/components/common/ProPlanCards'

const USTemplateDefault = ({
  lang = 'en',
  market = 'us',
  noRedirect,
  miceText,
  // Offer Code
  offerCode,
  offerBanner,
  // Addon Code
  addonCode,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  logo,
  logoHeight,
  title,
  subtitle,
  tagline,
  ctaText,
  video,
  videoMobile,
  staticAnnotation,
  annotations,
  poster,
  posterMobile,
  noteLink,
  heroCtaNote,
  // Optional Highlight Section
  highlightImage,
  highlightHeadline,
  highlightSubtitle,
  highlightNote,
  highlightBackground,
  highlightShowHeadlineTop,
  // Optional Highlight Section
  highlightNBA,
  // Network Section
  networkHeadline,
  networkSubtitle,
  networkSet,
  customNetworks,
  // Others
  team,
  // AB Test for RSN
  showRSN,
  showRedzone,
  redzoneOfferCode,
  // Show offer input for unique codes
  hasOfferInput,
  // AB Test - Video vs Logo Soup
  noVideo = false,
  // Remove references to cable
  removeCable,
  // Charter Plan Cards
  hasCharterPlanCards,
  // Optimum Plan Cards
  hasOptimumPlanCards,
  // Xfinity Plan Cards
  hasDirectvPlanCards,
  // Pro Plan Cards
  hasProPlanCards,
  // AB Test for MatchTicker
  matchtickerSportId,
  matchtickerLeagueName,
  matchtickerLeagueId,
  // Holiday Promo
  showHoliday,
  // Disable Free Trial
  disableFreeTrial = false,
  // AB test New Template
  newTemplate,
  testTemplate,
  // CA Redirect URL
  caRedirectUrl,
  // Personalization IDs
  sportId,
  leagueId,
  teamId,
  // FanDuel Plan Cards
  hasFanduelPlanCards,
  fanduelRSNData,
  showFDOnly,
  // Additional FAQs
  additionalFAQs,
}) => {
  // Disable Free Trial Modifications
  const modifiedCtaText =
    ctaText || (disableFreeTrial && (lang === 'es' ? 'Acceder Ahora' : 'Start Watching'))
  const modifiedNetworkSubtitle =
    networkSubtitle ||
    (disableFreeTrial && (lang === 'es' ? 'Cancela cuando quieras.' : 'Cancel online anytime.'))

  let metaDescription =
    pageDescription ||
    'Stream live TV with ABC, CBS, FOX, NBC, ESPN & top channels without cable. DVR included. No contract, cancel anytime.'

  if (team) {
    metaDescription = `Stream ${team} live online on ABC, CBS, FOX, NBC, and ESPN with Fubo. No contract is required - try it for free and cancel anytime.`
  }

  const stateForProtobuf = useSelector(selectStateForProtobuf)

  const handleLocaleSwitch = () => {
    fireUIInteractionEvent(
      {
        cta_name: noteUrl,
        container_name: 'lp_locale_switch_clicked',
      },
      stateForProtobuf
    )
  }

  const originalParams = useSelector(selectAnalyticsParameters)
  let noteUrl
  if (noteLink) {
    noteUrl = createUrl(noteLink, originalParams)
  }

  let noteText =
    lang === 'es' ? (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        To learn more in English, <span>click here</span>.
      </Link>
    ) : (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        Para traducir a español, <span>haga clic aquí</span>.
      </Link>
    )

  let charterNoteText =
    lang === 'es' ? (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        English
      </Link>
    ) : (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        Español
      </Link>
    )

  const hasAnchor = hasFanduelPlanCards ? '#select-plan' : null
  const hasOffer = offerBanner || offerCode

  if (hasFanduelPlanCards || offerBanner === ' ') {
    offerBanner = null
  } else if (!hasOffer) {
    offerBanner = (
      <>
        ✔ Special offer applied! <br /> Save $20 on your first month of Fubo
      </>
    )
  }

  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      offerBanner={offerBanner}
      addonCode={addonCode}
      ctaHrefOverride={hasAnchor}
      noteText={noteUrl && hasCharterPlanCards && charterNoteText}
      noRedirect={noRedirect}
      miceText={miceText}
      caRedirectUrl={caRedirectUrl}
      sportId={sportId}
      leagueId={leagueId}
      teamId={teamId}
    >
      <LogoSyncContextProvider annotations={annotations}>
        <SEO lang={lang} market={market} title={pageTitle || title} description={metaDescription} />
        {matchtickerLeagueId && (
          <>
            <MatchTicker
              lang={lang}
              market={market}
              sportId={matchtickerSportId}
              leagueName={matchtickerLeagueName}
              leagueId={matchtickerLeagueId}
            />
            {/* <MatchTickerTestContainer
            lang={lang}
            market={market}
            sportId={matchtickerSportId}
            leagueName={matchtickerLeagueName}
            leagueId={matchtickerLeagueId}
          /> */}
          </>
        )}

        <HeroSectionNew
          logo={logo}
          logoHeight={logoHeight}
          lang={lang}
          market={market}
          heroBgVideo={video}
          heroBgVideoMobile={videoMobile}
          title={title}
          subtitle={subtitle}
          tagline={tagline}
          ctaText={modifiedCtaText}
          staticAnnotation={staticAnnotation}
          posterImg={poster}
          posterImgMobile={posterMobile}
          noteText={heroCtaNote || (noteUrl && !hasCharterPlanCards && noteText)}
          hasOfferInput={hasOfferInput}
          noVideo={noVideo}
          ctaHrefOverride={hasAnchor}
          offerCode={offerCode}
          addonCode={addonCode}
          charterNoteText={noteUrl && hasCharterPlanCards && charterNoteText}
        />
        {showRSN && (
          <RSNGeolocationBannerNew
            hasOfferInput={hasOfferInput}
            ctaText={modifiedCtaText}
            ctaHrefOverride={hasAnchor}
          />
        )}
        {showRedzone && <RedzoneBanner redzoneOfferCode={redzoneOfferCode} />}

        {highlightImage && (
          <HighlightSection
            image={highlightImage}
            title={highlightHeadline}
            subtitle={highlightSubtitle}
            miceText={highlightNote}
            background={highlightBackground}
            showHeadlineTop={highlightShowHeadlineTop}
          />
        )}
        {highlightNBA && <HighlightNBA />}
        {hasFanduelPlanCards && (
          <FanduelPlanCards
            ctaText={ctaText}
            showFDOnly={showFDOnly}
            fanduelRSNData={fanduelRSNData}
          />
        )}
        {hasProPlanCards && <ProPlanCards ctaText={ctaText} />}
        {hasCharterPlanCards && (
          <CharterPlanCards lang={lang} market={market} ctaText="Start Your Free Trial" />
        )}
        {hasOptimumPlanCards && (
          <OptimumPlanCards lang={lang} market={market} ctaText="Start Your Free Trial" />
        )}
        {hasDirectvPlanCards && (
          <DirectvPlanCards lang={lang} market={market} ctaText="Start Your Free Trial" />
        )}
        {/* {hasPlanCards && <USPlanCards lang={lang} market={market} />} */}
        {!hasCharterPlanCards &&
          (newTemplate ? (
            <NetworkSectionNew
              lang={lang}
              market={market}
              networkHeadline={networkHeadline}
              subtitle={modifiedNetworkSubtitle}
              networkSet={networkSet}
              customNetworks={customNetworks}
              ctaText={modifiedCtaText}
              hasOfferInput={hasOfferInput}
              ctaHrefOverride={hasAnchor}
            />
          ) : (
            <NetworkSection
              lang={lang}
              market={market}
              networkHeadline={networkHeadline}
              subtitle={modifiedNetworkSubtitle}
              networkSet={networkSet}
              customNetworks={customNetworks}
              ctaText={modifiedCtaText}
              hasOfferInput={hasOfferInput}
              ctaHrefOverride={hasAnchor}
            />
          ))}
        <ChannelSection
          lang={lang}
          market={market}
          title={
            removeCable &&
            (lang === 'es'
              ? 'Disfruta de los deportes, programas, noticias y películas que te encantan.'
              : 'Enjoy the sports, shows, news and movies you love')
          }
          initialChannelCount={hasCharterPlanCards && 116}
        />
        {newTemplate ? (
          <DealSectionNew
            lang={lang}
            market={market}
            title={
              removeCable &&
              (lang === 'es' ? '¿Por qué elegir Fubo?' : 'Why you should choose Fubo')
            }
            savingsText={
              removeCable &&
              (lang === 'es'
                ? 'Fubo tiene los deportes en vivo que quieres a un precio que te encantará.'
                : 'Fubo has the live sports you want at a price you’ll love.')
            }
          />
        ) : (
          <DealSection
            lang={lang}
            market={market}
            title={
              removeCable &&
              (lang === 'es' ? '¿Por qué elegir Fubo?' : 'Why you should choose Fubo')
            }
            savingsText={
              removeCable &&
              (lang === 'es'
                ? 'Fubo tiene los deportes en vivo que quieres a un precio que te encantará.'
                : 'Fubo has the live sports you want at a price you’ll love.')
            }
          />
        )}
        {newTemplate ? (
          <PlatformSectionNew
            lang={lang}
            market={market}
            ctaText={modifiedCtaText}
            hasOfferInput={hasOfferInput}
            ctaHrefOverride={hasAnchor}
          />
        ) : (
          <PlatformSection
            lang={lang}
            market={market}
            ctaText={modifiedCtaText}
            hasOfferInput={hasOfferInput}
            ctaHrefOverride={hasAnchor}
          />
        )}
        {newTemplate && (
          <ExtraCTABanner
            hasOfferInput={hasOfferInput}
            ctaText={modifiedCtaText}
            ctaHrefOverride={hasAnchor}
          />
        )}
        {newTemplate ? (
          <FAQSectionNew
            lang={lang}
            market={market}
            team={team}
            removeCable={removeCable}
            disableFreeTrial={disableFreeTrial}
            additionalFAQs={additionalFAQs}
          />
        ) : (
          <FAQSection
            lang={lang}
            market={market}
            team={team}
            removeCable={removeCable}
            disableFreeTrial={disableFreeTrial}
            additionalFAQs={additionalFAQs}
          />
        )}
      </LogoSyncContextProvider>
    </Layout>
  )
}

export default USTemplateDefault
