import { networkLogos } from 'src/constants/networkLogos'
import { leagueLogos } from 'src/constants/leagueLogos'

export const networkSets = {
  default: [
    networkLogos.abc,
    networkLogos.cbs,
    networkLogos.fox,
    networkLogos.nbc,
    networkLogos.espn,
    networkLogos.fx,
    networkLogos.fs1,
  ],
  // Baseball (MLB)
  baseball: [
    // MLB.TV
    networkLogos.mlbtv,
    // FOX
    networkLogos.fox,
    // ESPN
    networkLogos.espn,
    // FOX Sports 1
    networkLogos.fs1,
    // RSN's
    // MLB Network
    networkLogos.mlbnetwork,
    networkLogos.cbs,
    // ESPN2
    networkLogos.espn2,
  ],
  // Football (NFL, college)
  football: [
    // FOX
    networkLogos.fox,
    // NFL Network
    networkLogos.nflnetwork,
    // CBS
    networkLogos.cbs,
    // NBC
    networkLogos.nbc,
    // ESPN
    networkLogos.espn,
    // ACC Network
    networkLogos.acc,
    // SEC Network
    networkLogos.sec,
  ],
  // Football (NFL, college)
  collegeFootball: [
    // ACC Network
    networkLogos.acc,
    // SEC Network
    networkLogos.sec,
    // Big Ten
    networkLogos.bigten,
    // ESPN
    networkLogos.espn,
    // FOX
    networkLogos.fox,
    // NBC
    networkLogos.nbc,
    // Pac-12
    networkLogos.nflnetwork,
  ],
  nfl: [
    // NFL Network
    networkLogos.nflnetwork,
    // NFL Network
    networkLogos.nflredzone,
    // ESPN
    networkLogos.espn,
    // CBS
    networkLogos.cbs,
    // FOX
    networkLogos.fox,
    // NBC
    networkLogos.nbc,
    // B1G Network
    networkLogos.bigten,
  ],

  // Basketball (NBA, college)
  basketball: [
    // ESPN
    networkLogos.espn,
    // ABC
    networkLogos.abc,
    // NBA TV
    networkLogos.nbatv,
    // CBSSN
    networkLogos.cbs,
    // Pac-12
    networkLogos.nflnetwork,
    // ACC Network
    networkLogos.acc,
    // SEC Network
    networkLogos.sec,
    // Stadium
  ],

  // Basketball (NBA, college)
  womensBasketball: [
    // ESPN
    networkLogos.espn,
    // CBSSN
    networkLogos.cbs,
    // ION
    networkLogos.ion,
    // NBA TV
    networkLogos.nbatv,
    // ACC Network
    networkLogos.acc,
    // SEC Network
    networkLogos.sec,
    // ESPN2
    // Pac-12
    networkLogos.abc,
    // Stadium
  ],

  // Soccer (all coverage, non-Latino)
  soccer: [
    // ESPN
    networkLogos.espn,
    // ABC
    networkLogos.abc,
    // FS1
    networkLogos.fs1,
    // USA Network
    networkLogos.usa,
    // FS2, but I replace with Bein
    networkLogos.bein,
    // ESPNU
    networkLogos.tudn,
    // ESPN2, but I replace with GolTV
    networkLogos.goltv,
  ],

  hockey: [
    networkLogos.espn,
    networkLogos.abc,
    networkLogos.fs1,
    networkLogos.usa,
    networkLogos.cbs,
    networkLogos.bein,
    networkLogos.tudn,
  ],

  // Misc Sports not covered by other categories
  sports: [
    networkLogos.espn,
    networkLogos.fs1,
    networkLogos.usa,
    networkLogos.bein,
    networkLogos.nflnetwork,
    networkLogos.tudn,
    networkLogos.cbs,
  ],

  // Entertainment
  entertainment: [
    // ABC (Grey’s Anatomy, The Bachelor/Bachelorette, Abbott Elementary, American Idol)
    networkLogos.abc,
    // CBS (NCIS, FBI, Young Sheldon, Survivor)
    networkLogos.cbs,
    // NBC (Law & Order: SVU, Young Rock, America’s Got Talent, The Voice)
    networkLogos.nbc,
    // FOX (Masterchef, Beat Shazam, So You Think You Can Dance, Family Guy)
    networkLogos.fox,
    networkLogos.fx,
    networkLogos.espn,
    networkLogos.freeform,
  ],
  // News
  news: [
    // Fox News
    networkLogos.foxnews,
    // MSNBC
    networkLogos.msnbc,
    // ABC News
    networkLogos.abcnews,
    // Fox Business
    networkLogos.foxbusiness,
    // CBS News
    networkLogos.cbsnews,
    // Young Turks?
    networkLogos.tyt,
    // Weather Channel
    networkLogos.weatherchannel,
    // TBD
  ],
  // Latino Soccer (all coverage)
  soccerLatino: [
    // TUDN
    networkLogos.tudn,
    // beIN SPORTS
    networkLogos.bein,
    // FOX Deportes
    networkLogos.foxdeportes,
    // ESPN Deportes
    networkLogos.espndeportes,
    // Gol TV
    networkLogos.goltv,
    // TyC Sports
    networkLogos.tyc,
    // Univision
    networkLogos.univision,
    // FSN Latino
    // networkLogos.fsnlatino,
  ],
  // Latino Entertainment and Soccer (all coverage)
  entertainmentLatino: [
    // bein SPORTS
    networkLogos.bein,
    // TUDN
    networkLogos.tudn,
    // Univision
    networkLogos.univision,
    // Galavision
    networkLogos.unimas,
    // FOX Deportes
    networkLogos.foxdeportes,
    // ESPN Deportes
    networkLogos.espndeportes,
    // TyC Sports
    networkLogos.tyc,
    // FSN Latino
  ],
  // Canada WITH Premium
  canada: [
    // HGTV
    networkLogos.hgtvca,
    // Food Network
    networkLogos.foodnetworkca,
    // Global Television
    networkLogos.global,
    // Disney
    networkLogos.disney,
    // Teletoon
    networkLogos.cartoonnetwork,
    // Treehouse
    networkLogos.treehouse,
    // W Network
    networkLogos.wnetwork,
  ],
  // Canada Essentials
  canadaEssentials: [
    // PL
    leagueLogos.pl,
    // Serie A
    leagueLogos.seriea,
    // Coppa Italia
    leagueLogos.coppaitalia,
    // beIN SPORTS
    networkLogos.bein,
    // CBC
    networkLogos.cbc,
    // Paramount Network
    networkLogos.nbatvca,
    // MLB Network
    networkLogos.mlbnetwork,
  ],
}
