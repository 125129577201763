import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getEnvironment } from 'src/config/env'
import { selectMarketState, selectPostalCode } from 'src/modules/reducers/market'

const packages = {
  'SOUTH + SOUTHEAST': { offerCode: null, price: 29.99 },
  'SOUTHWEST ONLY': { offerCode: null, price: 19.99 },
  'SOCAL + WEST': { offerCode: 'us-recu-ga-fanduel_sc_ws-mo-7d-v1', price: 29.99 },
  'WEST ONLY': { offerCode: null, price: 19.99 },
  'SOCAL ONLY': { offerCode: null, price: 19.99 },
  'FLORIDA + SUN ONLY': { offerCode: 'us-recu-ga-fanduel_florida_sun-mo-7d-v1', price: 29.99 },
  'MIDWEST + NORTH': { offerCode: null, price: 29.99 },
  'NORTH ONLY': { offerCode: null, price: 19.99 },
  'MIDWEST ONLY': { offerCode: null, price: 19.99 },
  'MIDWEST + OHIO': { offerCode: null, price: 29.99 },
  'OHIO + SOUTH + SOUTHEAST': { offerCode: null, price: 29.99 },
  'OHIO + SOUTH': { offerCode: null, price: 29.99 },
  'OHIO ONLY': { offerCode: null, price: 19.99 },
  'DETROIT ONLY': { offerCode: null, price: 19.99 },
  'SOUTH ONLY': { offerCode: null, price: 19.99 },
  'SOUTH + SOUTHEST': { offerCode: null, price: 29.99 },
  'OHIO + DETROIT': { offerCode: null, price: 29.99 },
  'OKLAHOMA ONLY': { offerCode: null, price: 19.99 },
  'WISCONSIN ONLY': { offerCode: null, price: 19.99 },
}

const env = getEnvironment()

export const useCheckFanduelRSN = () => {
  const postalCode = useSelector(selectPostalCode)
  const { regionCode } = useSelector(selectMarketState)
  const [showFDPlanCards, setShowFDPlanCards] = useState(false)
  const [rsnData, setRSNData] = useState(null)

  // Fetch RSN data for Fanduel Sports
  const fetchRSNs = (postalCode, regionCode) => {
    return fetch(`${env.default.host}/data/fd_data/fd_${regionCode}.json`)
      .then(response => response.json())
      .then(data => {
        const pkg = data[postalCode]?.package
        if (packages[pkg]?.offerCode) {
          return { ...data[postalCode], ...packages[pkg], regionCode }
        }
        return null
      })
      .catch(error => console.error('Error fetching data:', error))
  }

  // Check if Fanduel Sports is available in the user's postal code
  useEffect(() => {
    if (postalCode) {
      let isMounted = true
      fetchRSNs(postalCode, regionCode)
        .then(result => {
          if (isMounted && result) {
            setShowFDPlanCards(true)
            setRSNData(result)
          }
        })
        .catch(error => console.error('Error fetching data:', error))

      return () => {
        isMounted = false // Cleanup function to set isMounted to false
      }
    }
  }, [postalCode, regionCode])

  return [showFDPlanCards, rsnData]
}
