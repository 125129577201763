import React from 'react'
import { networkLogos } from 'src/constants/networkLogos'
import { offerCodes } from 'src/constants/offerCodes'

const COMMON_FEATURES = {
  CLOUD_DVR: 'Unlimited Cloud DVR included',
  MULTI_SCREEN: 'Watch on up to 10 screens at once',
  NFL_GAMES: 'Access to NFL games',
}

const formatTeamsText = (teams = []) => {
  if (!teams?.length) return ''
  return teams.length > 2
    ? `Watch the ${teams[0]}, ${teams[1]}, and more`
    : `Watch the ${teams.join(' and the ')}`
}

export const usPlanData = (channels, fanduelRSNData = null) => {
  const proChannels = channels?.['pro'] || '150+'
  const eliteChannels = channels?.['elite'] || '200+'
  const isFanduel = fanduelRSNData !== null
  const proPlanValues = isFanduel
    ? {
        items: [
          { available: true, text: `200+ top channels of live TV & sports` },
          {
            available: true,
            text: formatTeamsText(fanduelRSNData?.teams),
          },
          { available: true, text: COMMON_FEATURES.CLOUD_DVR },
          { available: true, text: COMMON_FEATURES.MULTI_SCREEN },
          { available: true, text: COMMON_FEATURES.NFL_GAMES },

          { available: true, text: 'ABC, CBS, FOX, NBC, ESPN & more' },
        ].filter(Boolean),
        disclaimer: <>Regional restrictions apply. Additional taxes and fees may apply.</>,
      }
    : {
        items: [
          { available: true, text: 'Your local NBA, NHL and MLB teams^' },
          { available: true, text: `200+ top live TV & sports channels` },
          { available: true, text: COMMON_FEATURES.CLOUD_DVR },
          { available: true, text: 'Watch on up to 10 screens' },
          { available: true, text: COMMON_FEATURES.NFL_GAMES },
        ],
        disclaimer: (
          <>
            *$59.99 for first month, $79.99/mo thereafter. <br /> Additional taxes may apply.
            ^Requires additional fees where available.
          </>
        ),
      }
  return {
    pro: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/us/pro-plan-card-us.png?ch=width&auto=format,compress',
      offerCode: offerCodes.pro,
      plan: 'pro',
      title: 'Fubo Pro',
      price: 79.99,
      discountedPrice: 59.99,
      savingsNote: 'Save $20',
      listItems: proPlanValues.items,
      asterisk: true,
      footnote: proPlanValues.disclaimer,
      networkLogos: [
        { logo: networkLogos.abc.logoWhite, alt: networkLogos.abc.alt, width: 44, height: 44 },
        { logo: networkLogos.fox.logoWhite, alt: networkLogos.fox.alt, width: 55, height: 24 },
        {
          logo: networkLogos.nflnetwork.logoWhite,
          alt: networkLogos.nflnetwork.alt,
          width: 71,
          height: 49,
        },
        { logo: networkLogos.espn.logoUrl, alt: networkLogos.espn.alt, width: 75, height: 19 },
        {
          alt: '+ More',
        },
      ],
    },
    essential: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/us/card-og-receiver-cropped.png?w=600&ch=width&auto=format,compress',
      offerCode: offerCodes.essential,
      plan: 'essential',
      title: 'Fubo Essential',

      price: 79.99,
      discountedPrice: 59.99,
      savingsNote: 'Save $20',
      listItems: [
        { available: false, text: 'Your local NBA, NHL and MLB teams' },
        { available: true, text: `100+ free live TV channels` },
        { available: true, text: COMMON_FEATURES.CLOUD_DVR },
        { available: true, text: 'Watch on up to 10 screens' },
        { available: true, text: COMMON_FEATURES.NFL_GAMES },
      ],
      asterisk: true,
      footnote: <>$59.99 for first month, $79.99/mo thereafter. Additional taxes may apply.</>,
    },
    elite: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/us/card-og-receiver-cropped.png?w=600&ch=width&auto=format,compress',
      offerCode: offerCodes.elite,
      plan: 'elite',
      title: 'Fubo Elite',
      price: 89.99,
      discountedPrice: 69.99,
      listItems: [
        { available: true, text: `${eliteChannels} channels` },
        { available: true, text: COMMON_FEATURES.CLOUD_DVR },
        { available: true, text: COMMON_FEATURES.MULTI_SCREEN },
        { available: true, text: '4k included' },
      ],
      asterisk: true,
      footnote: (
        <>
          *Paid as a monthly subscription (min. of $89.99/mo.) <br />
          Additional taxes & fees may apply.
        </>
      ),
    },
    fanduelsports: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/fanduel-sports-plan-card-bg.png?ch=width&auto=format,compress',
      offerCode: fanduelRSNData?.offerCode,
      // plan: fanduelRSNData?.package, //TODO: add this back in when we have the data
      title: 'FanDuel Sports Network',
      plan: fanduelRSNData?.package,
      price: fanduelRSNData?.price,
      savingsNote: true,
      listItems: [
        { available: true, text: `100+ free channels of live TV` },
        {
          available: true,
          text: `Watch the ${
            fanduelRSNData?.teams?.length > 2
              ? `${fanduelRSNData.teams[0]}, ${fanduelRSNData.teams[1]}, and more`
              : fanduelRSNData?.teams?.join(' and the ')
          }`,
        },
        { available: true, text: COMMON_FEATURES.CLOUD_DVR },
        { available: true, text: COMMON_FEATURES.MULTI_SCREEN },
        { available: false, text: COMMON_FEATURES.NFL_GAMES },
        { available: false, text: 'ABC, CBS, FOX, NBC, ESPN & more' },
      ],
      asterisk: false,
      footnote: <>Regional restrictions apply. Additional taxes and fees may apply.</>,
      networkLogos: fanduelRSNData?.rsn && [
        ...fanduelRSNData.rsn.map(network => ({
          logo: networkLogos[network.toLowerCase().replace(/\s+/g, '_')]?.logoWhite,
          alt: network,
          width: 135,
          height: 48,
        })),
      ],
    },
  }
}
