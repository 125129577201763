// Query String Params
export const FTV_CAMPAIGN = 'ftv_campaign'
export const COUPON_PARAM = 'coupon'
export const HEADLINE = 'headline'
export const HEADLINE2 = 'headline2'
export const PACK = 'pack'
export const OFFER = 'offer'
export const IRAD = 'irad'
export const IRMP = 'irmp'
export const SHARED_ID = 'sharedid'
export const PROMO = 'promo'
export const ADDONS = 'addons' // NTS: maybe switch back to addons[]
export const AL = 'al'
export const TALKABLE_VISITOR_UUID = 'talkable_visitor_uuid'
export const PROMO_CODE = 'promocode'
export const PARAMS = 'params'
export const AB_CAMPAIGN = 'ab_campaign'
export const APP_SOURCE = 'app_source'
export const SPORT_ID = 'sportid'
export const LEAGUE_ID = 'leagueid'
export const TEAM_ID = 'teamid'

export const footballCTAURI = '/football'
export const streamTVCTAURI = '/streamtv'
export const defaultCTAURI = '/signup'

export const imgixURI = 'https://assets-imgx.fubo.tv/marketing/lp/images/'
export const videoURI = 'https://assets.fubo.tv/marketing/lp/videos/'

export const ctaParamList = [
  FTV_CAMPAIGN,
  COUPON_PARAM,
  HEADLINE,
  HEADLINE2,
  PACK,
  OFFER,
  IRAD,
  IRMP,
  SHARED_ID,
  PROMO,
  ADDONS,
  AL,
  TALKABLE_VISITOR_UUID,
  PROMO_CODE,
  PARAMS,
  AB_CAMPAIGN,
  APP_SOURCE,
  SPORT_ID,
  LEAGUE_ID,
  TEAM_ID,
]

// Start Your Free Trial
// > Get Access
export const DefaultCtaText = 'Start your free trial'

// Start For Free
// > Get Access
export const DefaultCtaTextShort = 'Start For Free'

// Start Free Trial
// > Get Access
export const DefaultCtaTextAlt = 'Start For Free'

// Start for free. Cancel online anytime.
// > Start watching now. Cancel online anytime.
export const DefaultNetworkSubtitle = 'Start for free. Cancel online anytime.'

// Remove all Free Trial Sections.
// Use regex in search.

// Notable exeptions with Free Trial text
// src/components/page/movers/components/SideBySideSection/index.js
// src/components/page/live/components/HeroLiveFeed/components/hero.js, const cta_subhead & preview_cta_text
// src/components/page/fubotv-plus-aaa/index.js, remove <PlanSection/> and <TrialReminder/> components

// Canada EPL Pages
export const CaEplDefaultCtaText = 'Get Access'

// Latino Pages
export const LatinoDefaultCtaText = 'Start Your Free Trial'
export const LatinoDefaultCtaTextShort = 'Start For Free'
// export const LatinoDefaultCtaText = "Get Access"
// export const LatinoDefaultCtaTextShort = "Get Access"

// Latino ES Pages
export const LatinoESDefaultCtaText = 'Pruébalo Gratis'
// export const LatinoESDefaultCtaText = "Acceder Ahora"

export const isCAFreeTrial = false
export const CanadaDefaultCtaText = isCAFreeTrial ? 'Start Your Free Trial' : 'Get Access'
