import React from 'react'
import styled from 'styled-components'

import IPhoneAppImg from './images/iPhoneAppImg'
import AndroidImg from './images/androidAppImg'
import AmazonFireImg from './images/amazonFireImg'
import RokuImg from './images/rokuImage'
import AppleTVImg from './images/appleTVImg'
import HisenseImg from './images/hisenseImg'
import LGImg from './images/lgImg'

import headerBgImg from 'src/assets/images/stars.png'

import { defaultCTAURI, LatinoESDefaultCtaText } from 'src/modules/utils/constants'
import { DefaultCtaTextShort } from 'src/modules/utils/constants'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import CTAButton from 'src/components/common/CTAButton'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'

const PlatformSection = ({ ctaText, ctaHrefOverride, lang = 'en', onClick, hasOfferInput }) => {
  // get ctaParams from redux
  const parsedParams = useSelector(selectCTAParams)
  const stateForProtobuf = useSelector(selectStateForProtobuf)

  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  const ctaCopy = ctaText ? ctaText : lang === 'en' ? DefaultCtaTextShort : LatinoESDefaultCtaText
  const sectionTitle =
    lang === 'en'
      ? 'Stream Live & On-Demand from Anywhere'
      : 'Transmisión en Vivo y On-Demand Desde Cualquier Lugar'
  const sectionSubtitle =
    lang === 'en' ? 'On your favorite devices' : 'En tus dispositivos favoritos'
  const cancelText = lang === 'en' ? 'Cancel online anytime.' : 'Cancela cuando quieras.'

  const handleOnClick = e => {
    fireUIInteractionEvent(
      {
        cta_name: ctaCopy,
        container_name: 'platform_section_cta_clicked',
      },
      stateForProtobuf
    )
  }

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'platform')

  return (
    <SectionElement>
      <SectionHeader>
        <h2>{sectionTitle}</h2>
        <h3>{sectionSubtitle}</h3>
      </SectionHeader>

      <SectionContent>
        <ListElement>
          <ListItem>
            <IPhoneAppImg />
          </ListItem>
          <ListItem>
            <AndroidImg />
          </ListItem>
          <ListItem>
            <LGImg />
          </ListItem>
          <ListItem>
            <HisenseImg />
          </ListItem>
          <ListItem>
            <AmazonFireImg />
          </ListItem>
          <ListItem>
            <RokuImg />
          </ListItem>
          <ListItem>
            <AppleTVImg />
          </ListItem>
          <ListItem className="more-text">{lang === 'es' ? 'Y MÁS' : '+ MORE'}</ListItem>
        </ListElement>
        <SectionCTA>
          <ButtonWrapper>
            <CTAButton
              link={hasOfferInput ? '#' : ctaHrefOverride || ctaURL}
              onClick={onClick || handleOnClick}
            >
              {ctaCopy}
            </CTAButton>
          </ButtonWrapper>
          <p>{cancelText}</p>
        </SectionCTA>
      </SectionContent>
    </SectionElement>
  )
}

export default PlatformSection

const SectionElement = styled.section`
  text-align: center;
  background: #fafafa;
  padding-bottom: 5px;
  .more-text {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;
    white-space: nowrap;
    @media screen and (min-width: 320px) and (max-width: 736px) {
      font-size: 18px;
    }
  }
`

const SectionHeader = styled.div`
  background-color: #242526;
  padding: 65px 3% 45px;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  background: no-repeat 50% 30px url(${headerBgImg});
  background-color: #242526;

  h2 {
    color: #fff;
    font-weight: 700;
    font-weight: 700;
    line-height: 31.6px;
    margin-bottom: 5px;
    font-size: 24.32px;
  }

  h3 {
    color: rgba(255, 255, 255, 0.7);
    line-height: 33.6px;
    font-weight: 400;
    font-size: 22px;
  }
`

const SectionContent = styled.div`
  max-width: 1200px;
  margin: -20px auto 0;
  // padding: 0 15%;
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 736px) and (min-width: 320px) {
    padding: 0 10%;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`

const ListElement = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 320px) and (max-width: 736px) {
    flex-direction: column nowrap;
  }
`

const ListItem = styled.li`
  width: 30%;
  margin-right: 10px;
  width: 100%;
  max-width: 276px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  position: relative;
  margin: 0 12px 12px 0;

  @media screen and (min-width: 320px) and (max-width: 736px) {
    flex: 33%;
    width: 150px;
    margin-bottom: 6px;
    margin-right: 6px;

    :nth-last-child(-n + 3) {
      margin-top: 0;
    }
  }

  @media (max-width: 600px) {
    max-width: 30%;
  }

  img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    border-radius: 3px;
  }
`

const ButtonWrapper = styled.div`
  margin: 30px auto;
`

const SectionCTA = styled.div`
  color: rgb(64, 64, 64);

  button {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 30px;
    margin-top: 0;
    font-size: 14px;
  }
`
