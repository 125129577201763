// @flow

import type { Plan } from 'src/modules/flowTypes/domain'

import {
    FETCH_PLANS,
    FETCH_PLANS_SUCCESS,
    FETCH_PLANS_FAILURE,
} from './actionTypes'


export type PlanState = {
  plans: Array<Plan>,
  isFetchPending: boolean,
  isError: boolean,
  error: null,
}

const INITIAL_STATE = {
  plans: [],
  isFetchPending: false,
  isError: false,
  error: null,
}

export default function reducer(state: PlanState = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case FETCH_PLANS:
      return {
        ...state,
        isFetchPending: true,
      }

    case FETCH_PLANS_SUCCESS:
     return {
       ...state,
       plans: action.payload,
       isFetchPending: false,
     }

     case FETCH_PLANS_FAILURE:
        return {
          ...state,
          error: action.payload,
          isError: true,
          isFetchPending: false,
        };

    default:
      return state
    }
  }