import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'
import { useCheckFanduelRSN } from 'src/modules/utils/useCheckFanduelRSN'

const BallySportsNHL = props => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN()
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={props.video || videos.generic_hockey.videoUrl}
      staticAnnotation={props.staticAnnotation}
      title={props.title}
      subtitle={props.subtitle}
      tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
      networkSet="hockey"
      customNetworks={props.customNetworks || ['fanduelsports']}
      sportId={sportIds.Hockey}
      leagueId={leagueIds.NHL}
      hasFanduelPlanCards={showFDPlanCards}
      fanduelRSNData={rsnData}
      showFDOnly={props.showFDOnly}
      {...props}
    />
  )
}

export default BallySportsNHL
