import React from 'react'
import { networkLogos } from 'src/constants/networkLogos'

export const huluData = {
  competitor: 'Hulu + Live TV',
  competitorColor: '#1CE783',
  heroData: {
    title: 'Looking for the best streaming service?',
    subtitle:
      'Compare Fubo & Hulu + Live TV on price, features, channels, and add-ons to see which is better for you.',
  },
  generalCompareData: {
    heading: 'Compare Fubo vs. Hulu + Live TV',
    subheading:
      'Here’s a general look at both to help you determine which is the best streaming service for you.',
    listItems: ['Channel Count', 'Plans', 'Simultaneous streams', 'Starting Price'],
    fuboList: ['185+', '3', 'Up to 10', '$79.99/mo*'],
    competitorList: ['85+', '1', '2', '$76.99/mo*'],
  },
  prosConsData: {
    heading: 'Pros and Cons: Fubo vs. Hulu + Live TV',
    content: (
      <>
        <p>
          When it comes to cord cutting, Fubo and Hulu + Live TV are two of the best streaming
          services around. Both are substantially cheaper than cable and offer many of the same
          great live channels for live sports and popular entertainment. When it comes to picking
          between the two, it comes down to inches in terms of price, channels available, and
          features.
        </p>
        <p>
          Fubo is a more premium product and it has the price tag to match. Fubo plans are loaded
          with live channels (at least 185 live channels) and quality features. If you were to
          compare the channels available on Fubo vs. Hulu + Live TV, Fubo has an edge in local
          sports coverage with their regional sports networks. Plus, Fubo has an extensive
          video-on-demand library. While most streaming services have VOD content, Fubo has more
          than 40K TV shows and movies available via VOD each month as well as 55K live sporting
          events on the platform annually.
        </p>
        <p>
          Hulu + Live TV is a unique streaming service option for cord cutters. Firstly, they only
          have one plan option for cord cutters looking for live TV. So, that means no customization
          options outside of a few add-ons and, on top of that, Hulu + Live TV doesn’t offer a free
          trial, forcing new users to pay immediately. Hulu + Live TV is not much cheaper than Fubo
          and it has noticeably fewer channels. There’s a big hole in their local sports coverage of
          the NBA, MLB, and NHL. As for streaming live TV, they have one of the smaller caps on
          simultaneous streams, only allowing two active streams at the same time. Hulu + Live TV
          also has an expiration on recorded content, so users can never truly build their own
          on-demand library.
        </p>
      </>
    ),
  },
  plansData: {
    heading: 'Compare Fubo Pro plan vs. Hulu + Live TV',
    subheading:
      'Let’s take a look at the differences between the popular Fubo Pro plan and a basic Hulu + Live TV account.',
    tableData: {
      Plan: ['Pro plan', 'base'],
      'Live Channels': ['185+', '85+'],
      'Monthly Price': ['$79.99', '$76.99'],
      'Hours of DVR storage': ['Unlimited', 'Unlimited'],
      'Free Trial': ['Yes', 'No'],
      'Multiple active streams': ['Up to 10', 'Up to 2'],
      'Regional sports networks': [true, true],
      'Streaming from any device': [true, true],
    },
  },
  channelsData: {
    heading: 'Compare Fubo Channels vs. Hulu + Live TV Channels',
    subheading:
      'One of the biggest differences between Fubo and Hulu + Live TV is with their lineups of live sports channels. This chart compares which sports networks, including regional sports networks only accessible in select markets, are available with Fubo and Hulu.',
    networksData: [
      { network: networkLogos.nflnetwork, fubo: true, competitor: true },
      { network: networkLogos.mlbnetwork, fubo: true, competitor: false },
      { network: networkLogos.nbatv, fubo: true, competitor: false },
      { network: networkLogos.nhlnetwork, fubo: true, competitor: false },
      { network: networkLogos.espn, fubo: true, competitor: true },
      { network: networkLogos.tudn, fubo: true, competitor: false },
      { network: networkLogos.sec, fubo: true, competitor: true },
      { network: networkLogos.acc, fubo: true, competitor: true },
      { network: networkLogos.bigten, fubo: true, competitor: true },
      // { network: networkLogos.pac12, fubo: true, competitor: false },
      { network: networkLogos.fs1, fubo: true, competitor: true },
      { network: networkLogos.yesnetwork, fubo: true, competitor: false },
      { network: networkLogos.nesn, fubo: true, competitor: false },
      { network: networkLogos.fanduelsports, fubo: true, competitor: false },
      { network: networkLogos.masn, fubo: true, competitor: false },
    ],
  },
  completedChannelsData: {
    heading: 'Complete Channel Comparison',
    subheading: 'See how the live sports channel lineups compare between Fubo and Hulu + Live TV.',
    content: (
      <>
        <p>
          When it comes to cord cutting, Fubo and Hulu + Live TV are two of the best streaming
          services. Both are substantially cheaper than cable and offer many of the same great live
          channels for live sports and popular entertainment. When it comes to picking between the
          two, it comes down to inches in terms of price, channels available, and features.
        </p>
        <p>
          Fubo has the most local sports for the lowest price as well as great local network
          coverage for ABC, CBS, FOX, and NBC. This makes Fubo one of the best streaming services
          for cord cutters who want to keep their local channels. They have a lot of the most
          popular networks in their lineup. Besides having regional sports networks like NESN, Bally
          Sports, and MASN in select local markets, Fubo also has MLB Network and NHL Network.
        </p>
        <p>
          Hulu + Live TV also has a good lineup of live channels. They have many of the same
          channels available on Fubo with a few key differences. Hulu + Live TV lacks some of the
          major league sports networks like MLB Network and NBA TV but does have WarnerMedia
          networks. Hulu + Live TV only has a small number of regional sports networks.
        </p>
      </>
    ),
  },
  faqData: {
    qaData: [
      {
        id: 'fubo-faq-5',
        question: 'What channels does Fubo have vs. Hulu + Live TV?',
        answer: (
          <p>
            The channels Fubo has over Hulu + Live TV include MLB Network, NBA TV & more. Fubo also
            has local sports coverage on NESN, FanDuel Sports & MASN in select local markets.
          </p>
        ),
      },
    ],
  },
}
