import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import headerBgImg from 'src/assets/images/stars.png'
import { faqs } from 'src/constants/faqs'

const FAQSection = ({
  lang = 'en',
  market = 'us',
  team,
  additionalFAQs,
  foxRedirect,
  removeCable,
  disableFreeTrial,
  canadaEnt,
}) => {
  const [activeFaqsList, setActiveFaqsList] = useState([])

  useEffect(() => {
    let faqsList = [...faqs[market][lang]].map(faq => ({ ...faq }))
    if (canadaEnt) {
      const soccerIndex = faqsList.findIndex(faq => faq.id === 'soccer')
      if (soccerIndex !== -1) {
        faqsList[soccerIndex].answer = (
          <>
            Yes! Fubo networks broadcast Premier League, Ligue 1, Serie A, Coppa Italia, Süper Lig,
            Canadian World Cup Qualifiers and many other leagues and tournaments.
            <span style={{ fontSize: '.7em', fontStyle: 'italic', display: 'block' }}>
              *Sports and Premium plans only.
            </span>
          </>
        )
      }
    }
    if (team) {
      // add team FAQ
      const teamFAQ = {
        us: {
          question: `Can I watch ${team} online without cable?`,
          answer: `Yes, you can watch ${team} live online without cable when you start a
        trial of Fubo.`,
        },
        canada: {
          question: `Can I watch ${team} online without cable?`,
          answer: `Yes, you can watch ${team} live online without cable with Fubo.`,
        },
      }
      if (market === 'canada') {
        faqsList.splice(1, 0, teamFAQ.canada)
      } else {
        faqsList.splice(1, 0, teamFAQ.us)
      }
    }
    if (removeCable) {
      const nflIndex = faqsList.findIndex(faq => faq.id === 'nfl')
      if (nflIndex !== -1) {
        faqsList.splice(nflIndex, 1)
      }
      const whatIsFuboIndex = faqsList.findIndex(faq => faq.id === 'what-is-fubo')
      if (whatIsFuboIndex !== -1) {
        if (lang === 'es') {
          faqsList[whatIsFuboIndex].answer =
            'Fubo es un servicio que transmite televisión en vivo a través de Internet. Mira tus equipos favoritos, programas de cadenas, noticias y películas en más de 200 canales. Además de entretenimiento on-demand que incluye series de televisión completas.'
        } else {
          faqsList[whatIsFuboIndex].answer =
            'Fubo is a service that broadcasts live TV over the internet. Watch your favorite teams, network shows, news and movies on 200+ channels. Plus on-demand entertainment including full TV series.'
        }
      }
      const collegeFootballIndex = faqsList.findIndex(faq => faq.id === 'college-football')
      if (collegeFootballIndex !== -1) {
        if (lang === 'es') {
          faqsList[collegeFootballIndex].question = '¿Cómo puedo ver fútbol universitario online?'
          faqsList[collegeFootballIndex].answer =
            'Para ver fútbol universitario, simplemente inicia una prueba gratuita de Fubo. Puede comenzar a mirar inmediatamente en su televisor, teléfono, computadora y más. No existe contrato ni compromiso.'
        } else {
          faqsList[collegeFootballIndex].question = 'How can I watch college football online?'
          faqsList[collegeFootballIndex].answer =
            "To watch college football, just start a free trial of Fubo. You can start watching immediately on your TV, phone, computer and more. There's no contract and no commitment."
        }
      }
      const howMuchIndex = faqsList.findIndex(faq => faq.id === 'how-much')
      if (howMuchIndex !== -1) {
        if (lang === 'es') {
          faqsList[howMuchIndex].answer =
            'Los precios varían según su plan, región y complementos seleccionados. Nuestro plan más popular en los Estados Unidos incluye más de 200 canales de televisión y deportes en vivo.'
        } else {
          faqsList[howMuchIndex].answer =
            'Prices vary based on your plan, region and selected add-ons. Our most popular plan in the United States includes 200+ channels of live sports and TV.'
        }
      }
    }
    if (foxRedirect) {
      const nflIndex = faqsList.findIndex(faq => faq.id === 'nfl')
      const collegeFootballIndex = faqsList.findIndex(faq => faq.id === 'college-football')
      const howMuchIndex = faqsList.findIndex(faq => faq.id === 'how-much')
      if (nflIndex !== -1) {
        faqsList[nflIndex].question = 'How can I watch the NFL online?'
        faqsList[nflIndex].answer =
          "To watch the NFL, just start a free trial of Fubo. You can start watching immediately on your TV, phone, computer and more. There's no contract and no commitment."
      }
      if (collegeFootballIndex !== -1) {
        faqsList[collegeFootballIndex].question = 'How can I watch college football online?'
        faqsList[collegeFootballIndex].answer =
          "To watch college football, just start a free trial of Fubo. You can start watching immediately on your TV, phone, computer and more. There's no contract and no commitment."
      }
      if (howMuchIndex !== -1) {
        faqsList[howMuchIndex].answer = (
          <>
            Prices vary based on your plan, region and selected add-ons. Our most popular plan in
            the United States includes 200+ channels of live sports and TV for half the cost of the
            average monthly cable bills.{' '}
            <span style={{ fontSize: '.7em', fontStyle: 'italic', display: 'block' }}>
              *Cord Cutter News, March 2020
            </span>
          </>
        )
      }
    }
    if (disableFreeTrial) {
      const nflIndex = faqsList.findIndex(faq => faq.id === 'nfl')
      if (nflIndex !== -1) {
        faqsList.splice(nflIndex, 1)
      }
      const collegeFootballIndex = faqsList.findIndex(faq => faq.id === 'college-football')
      if (collegeFootballIndex !== -1) {
        faqsList.splice(collegeFootballIndex, 1)
      }
    }

    if (additionalFAQs) {
      faqsList.splice(1, 0, ...additionalFAQs)
    }

    setActiveFaqsList(faqsList)
  }, [lang, market, removeCable, team, foxRedirect])

  return (
    <StyledFAQSection>
      <div className="faq-heading">
        <h2>{lang === 'es' ? 'Preguntas Frecuentes' : 'Frequently Asked Questions'}</h2>
      </div>
      <div className="faq-body">
        <ul className="content">
          {activeFaqsList.map((faq, index) => (
            <li key={index}>
              <h4>{faq.question}</h4>
              <p>{faq.answer}</p>
            </li>
          ))}
        </ul>
        <div className="inquiry">
          <p>
            {lang === 'es' ? (
              <>
                Para cualquier duda o pregunta, visita nuestro{' '}
                <a href="https://support.fubo.tv/hc/es" target="_blank" rel="noreferrer">
                  Centro de Ayuda
                </a>
              </>
            ) : (
              <>
                Still have questions? Visit our{' '}
                <a href="https://support.fubo.tv/" target="_blank" rel="noreferrer">
                  Help Center
                </a>{' '}
                to find more answers and contact us.
              </>
            )}
          </p>
        </div>
      </div>
    </StyledFAQSection>
  )
}

export default FAQSection

const StyledFAQSection = styled.section`
  padding-bottom: 40px;
  font-size: 1.1em;
  background: #fafafa;
  p {
    padding-bottom: 15px;
    color: #666;
  }
  a {
    color: #00a0d7;
  }
  .faq-heading {
    padding: 65px 3% 45px;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    background: no-repeat 50% 30px url(${headerBgImg});
    background-color: #242526;
    text-align: center;

    h2 {
      color: #fff;
      font-weight: 700;
      font-size: 27px;
      line-height: 1.3em;
      margin: 0;
      margin-bottom: 5px;
    }
  }
  .faq-body {
    max-width: 1200px;
    margin: -20px auto 0;
    padding: 0 3%;
    .content {
      background: rgb(255, 255, 255);
      box-shadow: rgb(0 0 0 / 40%) 0px 0px 3px;
      border-radius: 3px;
      padding: 30px 40px;
      column-count: 2;
      column-gap: 60px;
      @media (max-width: 768px) {
        column-count: 1;
        padding: 30px 30px 20px;
      }
      li {
        column-break-inside: avoid;
      }
      h4 {
        color: #404040;
        font-weight: 500;
        margin-bottom: 7px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        margin: 0 0 10px;
      }
    }
    .inquiry {
      text-align: center;
      font-size: 14px;
      line-height: 22px;
    }
  }
`
