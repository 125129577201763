import { WEB_MARKETING_FAQ_MESSAGING, WEB_MARKETING_PRO_PLAN_CARDS,  } from './constants'

import type { BooleanValueFlag, Flag, StringValueFlag } from './types'

type MarketingFAQMessaging = typeof WEB_MARKETING_FAQ_MESSAGING
type MarketingProPlanCards = typeof WEB_MARKETING_PRO_PLAN_CARDS


function flag<K extends string, V>(key: K, value: V, ttl = -1): Flag<K, V> {
  return {
    key,
    value,
    ttl,
  }
}
function booleanFlag<K extends string>(
  key: K,
  value = false,
  ttl = -1
): BooleanValueFlag<K> {
  return flag<K, boolean>(key, value, ttl)
}
function stringFlag<K extends string>(
  key: K,
  value = 'default',
  ttl = -1
): StringValueFlag<K> {
  return flag<K, string>(key, value, ttl)
}

export const initValues = {
  [WEB_MARKETING_FAQ_MESSAGING]: stringFlag<MarketingFAQMessaging>(WEB_MARKETING_FAQ_MESSAGING),
  [WEB_MARKETING_PRO_PLAN_CARDS]: stringFlag<MarketingProPlanCards>(WEB_MARKETING_PRO_PLAN_CARDS),
} as const

export type StacFlags = typeof initValues
export type StacFlagKey = keyof StacFlags
export type StacFlagKeys = StacFlagKey[]

export const supportedFlags = Object.keys(initValues) as StacFlagKeys
