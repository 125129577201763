import React from 'react'
import { networkLogos } from 'src/constants/networkLogos'

export const philoData = {
  competitor: 'Philo',
  competitorColor: '#00B9FF',
  heroData: {
    title: 'Looking for the best streaming service?',
    subtitle:
      'Compare Fubo & Philo on price, features, channels and add-ons to see which is better for you.',
  },
  generalCompareData: {
    heading: 'Compare Fubo vs. Philo',
    subheading:
      'Here’s a general look at both to help you determine which is the best streaming service for you.',
    listItems: ['Channel Count', 'Available Content', 'Simultaneous Streams', 'Starting Price'],
    fuboList: ['185+', 'Sports & entertainment', 'Up to 10', '$79.99/mo.*'],
    competitorList: ['71', 'Entertainment', '3', '$25/mo.*'],
  },
  prosConsData: {
    heading: 'Pros and Cons: Fubo vs. Philo',
    content: (
      <>
        <p>
          When talking specifically about finding a live TV streaming service to replace traditional
          cable TV, Fubo is the better option between these two. The Fubo channel count and content
          is closer to what you’d see from a traditional cable package, only much more affordable.
          Philo, on the other hand, does offer a unique option to cord cutters looking for a cable
          alternative. However, the product is very limited, doesn’t offer much customization and
          heavily relies on being cheaper as its primary feature.
        </p>
        <p>
          Fubo is a more premium product with a price tag to match. Fubo plans are loaded with live
          channels (at least 185 live channels) and innovative features. If you were to compare the
          channels available on Fubo vs. Philo, the Fubo channel lineup is far superior with both
          their entertainment and sports content. Fubo also offers a lot of customization through
          multiple plans and add-on options. Plus, Fubo has an extensive video-on-demand library.
          While most streaming services have VOD content, Fubo has more than 40,000 TV shows and
          movies available via VOD each month as well as 55,000 live sporting events on the platform
          annually.
        </p>
        <p>
          Philo is a unique option for cord cutters. It’s got just one cheap plan with a small
          channel count of somewhat niche but bingeable programming. It even has a few high-demand
          channels among its selection but is missing a lot of the key channels you’d expect to see
          from cable TV or a cable replacement streaming service. With only three add-ons to choose
          from, Philo doesn't have a lot of ways to customize your TV plan. Philo feels like a
          streaming service for cord cutters who want to watch TV but don’t really care about what
          they’re watching.
        </p>
      </>
    ),
  },
  plansData: {
    heading: 'Compare Fubo Pro plan vs. Philo',
    subheading:
      'Let’s take a look at the differences between the popular Fubo Pro plan and a basic Philo account.',
    tableData: {
      Plan: ['Pro plan', 'Base'],
      Channels: ['185+ channels', '70 channels'],
      'Monthly Price': ['$79.99', '$25'],
      'Add-ons Available': ['23', '3'],
      'Free Trial': ['Yes', 'Yes'],
      'Multiple active streams': ['Up to 10', 'Up to 3'],
      'Regional sports networks': [true, false],
      'Streaming from any device': [true, true],
    },
  },
  channelsData: {
    heading: 'Compare Fubo Channels vs. Philo Channels',
    subheading:
      'While channel lineups can be adjusted, this channel comparison chart focuses on what’s available among the most-watched channels from 2023 in the Fubo Pro plan vs. a basic Philo account.',
    networksData: [
      { network: networkLogos.abc, fubo: true, competitor: false },
      { network: networkLogos.cbs, fubo: true, competitor: false },
      { network: networkLogos.nbc, fubo: true, competitor: false },
      { network: networkLogos.fox, fubo: true, competitor: false },
      { network: networkLogos.espn, fubo: true, competitor: false },
      { network: networkLogos.ion, fubo: true, competitor: false },
      { network: networkLogos.telemundo, fubo: true, competitor: false },
      { network: networkLogos.fx, fubo: true, competitor: false },
      { network: networkLogos.fs1, fubo: true, competitor: false },
      // { network: networkLogos.tlc, fubo: true, competitor: false },
      { network: networkLogos.univision, fubo: true, competitor: false },
      { network: networkLogos.hallmark, fubo: true, competitor: true },
      { network: networkLogos.nickelodeon, fubo: true, competitor: true },
      { network: networkLogos.mtv, fubo: true, competitor: true },
      { network: networkLogos.usa, fubo: true, competitor: false },
    ],
  },
  completedChannelsData: {
    heading: 'Complete Channel Comparison',
    subheading:
      'See how the live channel lineups compare between Fubo Pro and a basic Philo account.',
    content: (
      <>
        <p>
          When comparing channel lineups for Fubo and Philo, there are far more differences than
          similarities. Fubo offers a wider variety of channels with live sports, top TV shows,
          popular movies and news coverage. Philo is definitely more focused on delivering a smaller
          lineup with very bingeable content. The big difference comes down to what fits your needs
          and the needs of your household.
        </p>
        <p>
          The live channel lineup for Fubo is far more complete. Not only do they have 185+ live
          channels, they also have a majority of the most-watched channels from the past year. They
          have tons of live sports, including coverage for football, soccer, baseball, basketball
          and more. Plus, Fubo has popular news networks like FOX News Channel and MSNBC as well as
          popular entertainment channels like ION, FX, Hallmark Channel and USA Network.
        </p>
        <p>
          The channel lineup for Philo is so much smaller and includes a lot of specialty networks.
          Philo doesn’t offer any major sports coverage. They’re also missing every major broadcast
          network like CBS and ABC, which means Philo users don’t have access to major primetime,
          daytime, or late-night shows. The biggest perk with Philo really does come down to the
          price. At $25 per month, are entertainment channels like Nickelodeon, MTV, Hallmark
          Channel, and a few others good enough value?
        </p>
      </>
    ),
  },
  faqData: {
    qaData: [
      {
        id: 'fubo-faq-5',
        question: 'What channels does Fubo have vs. Philo?',
        answer: (
          <p>
            Fubo has a substantial edge over Philo with channel count. The channels Fubo has over
            Philo includes ABC, CBS, FOX, NBC, ESPN, FOX News Network, Telemundo, ION & more.
          </p>
        ),
      },
    ],
  },
}
