import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { defaultCTAURI, LatinoESDefaultCtaText } from 'src/modules/utils/constants'
import {
  DefaultCtaText,
  DefaultCtaTextShort,
  DefaultNetworkSubtitle,
} from 'src/modules/utils/constants'

import { createUrl } from 'src/modules/utils/createUrl'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import CTAButton from 'src/components/common/CTAButton'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'

const breakpoint = 981
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'
const HighlightSection = ({
  ctaText,
  image,
  title,
  subtitle,
  miceText,
  background,
  lang = 'en',
  ctaHrefOverride,
  showHeadlineTop = false,
}) => {
  const parsedParams = useSelector(selectCTAParams)
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const [isDesktop, setIsDesktop] = useState(false)

  let ctaURL = createUrl(defaultCTAURI, parsedParams)
  ctaURL = replaceUrlParam(ctaURL, 'comp', 'feature')

  const isEs = lang === 'es'
  const ctaCopy = ctaText ? ctaText : isEs ? LatinoESDefaultCtaText : DefaultCtaText
  const headerTitle = title || (isEs ? 'Lo Mejor de la TV en vivo' : 'Enjoy Your Free Trial')
  const handleOnClick = e => {
    fireUIInteractionEvent(
      {
        cta_name: ctaCopy,
        container_name: 'free_trial_section_cta_clicked',
      },
      stateForProtobuf
    )
  }

  const checkIsDesktop = () => {
    return window.innerWidth >= breakpoint
  }

  useEffect(() => {
    if (showHeadlineTop) {
      function handleResize() {
        setIsDesktop(checkIsDesktop())
      }
      handleResize()
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <SectionWrapper background={background} className="highlight-section">
      {!isDesktop && showHeadlineTop && (
        <SectionContent>
          <SectionTitle>{headerTitle}</SectionTitle>
        </SectionContent>
      )}
      <SectionInner className="highlight-section-inner">
        <ImageWrapper className="highlight-section-image">
          {image ? (
            <img src={image} alt="" />
          ) : (
            <img
              src="https://assets-imgx.fubo.tv/marketing/lp/images/devices/LP_group_fubo-devices-min-v2.png?ch=width&auto=format,compress"
              width="100%"
              alt=""
            />
          )}
        </ImageWrapper>
        <SectionContent className="highlight-section-content">
          {((showHeadlineTop && isDesktop) || !showHeadlineTop) && (
            <SectionTitle>{headerTitle}</SectionTitle>
          )}
          <SectionParagraph>
            {subtitle ||
              (isEs
                ? 'Deportes, entretenimiento y mucho más!'
                : "Get to know everything we offer with a free trial. We'll email you when it's almost over.")}
          </SectionParagraph>
          <SectionCTA>
            <ButtonWrapper>
              <CTAButton link={ctaHrefOverride || ctaURL} onClick={handleOnClick}>
                {ctaCopy}
              </CTAButton>
            </ButtonWrapper>
            {miceText && <div className="mice-text">{miceText}</div>}
          </SectionCTA>
        </SectionContent>
      </SectionInner>
    </SectionWrapper>
  )
}

export default HighlightSection

const SectionWrapper = styled.section`
  width: 100%;
  padding: 90px 0;
  background-color: #3e315e;
  background: ${props => props.background || 'linear-gradient(-45deg, #3e315e 0%, #1f182b 100%)'};

  @media (max-width: 981px) {
    padding: 30px 0;
  }

  .mice-text {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;

    @media (min-width: 982px) {
      text-align: left;
      margin-top: 20px;
    }
  }
`

const SectionInner = styled.div`
  display: flex;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  align-items: center;
  gap: 0 75px;

  @media (max-width: 981px) {
    flex-direction: column;
    padding: 10px 0 20px 0;
  }
`

const ImageWrapper = styled.div`
  width: 635px;
  ${'' /* margin-right: 75px; */}

  @media (min-width: 982px) {
    flex: 1 1 50%;
  }

  @media (max-width: 981px) {
    margin: 10px auto 30px auto;
    max-width: 75%;
  }

  img {
    width: 100%;
  }
`

const SectionContent = styled.div`
  max-width: 550px;
  color: #fff;

  @media (min-width: 982px) {
    flex: 1 1 50%;
  }

  @media (max-width: 981px) {
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`

const SectionTitle = styled.h2`
  font-size: 43px;
  line-height: 1.1;
  font-weight: 700;
  font-weight: 700;
  width: 100%;

  @media (max-width: 981px) {
    padding-bottom: 0;
    text-align: center;
  }

  @media (max-width: 736px) {
    font-size: 32px;
  }
`
const SectionParagraph = styled.p`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 30px;

  max-width: 460px;
  @media (max-width: 981px) {
    margin: 15px auto 30px;
  }
`

const SectionCTA = styled.div``

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 30px auto;
`
