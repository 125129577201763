import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SEO from 'src/components/common/SEO'
import FAQSection from 'src/components/common/FAQSection'
import PlatformSection from 'src/components/common/PlatformSection'
import NetworkSection from 'src/components/common/NetworkSection'
import DealSection from 'src/components/common/DealSection'
import Layout from 'src/components/common/Layout'
import ChannelSectionSimple from 'src/components/common/ChannelSectionSimple'
import HeroLiveFeed from 'src/components/common/HeroLivePlayer'
import BundlePlansCAControlVariant from 'src/components/common/BundlePlansCAByDuration'
import { useSelector } from 'react-redux'
import { selectParams } from 'src/modules/reducers/params'
import { selectCountryCode } from 'src/modules/reducers/market'
import { Helmet } from 'react-helmet'
import HighlightSection from 'src/components/common/HighlightSection'
import { selectExperiments } from 'src/modules/reducers/experiments'

const CanadaLiveTemplateDefault = ({
  location,
  lang = 'en',
  market = 'canada',
  ctaHrefOverride,
  // Offer Code
  offerCode,
  offerBanner,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  logo,
  logoHeight,
  title,
  subtitle,
  ctaText,
  background,
  callsign,
  // Optional Highlight Section
  highlightImage,
  highlightHeadline,
  highlightSubtitle,
  highlightNote,
  highlightBackground,
  // Pass Network Name to Live Preview
  networkName,
  // Plan Cards
  hasPlanCards = true,
  plan,
  plansTitle,
  plansSubtitle,
  soccerPlanCardImage,
  premiumPlanCardImage,
  forcePlanLength,
  // Network Section
  networkHeadline,
  networkSet = 'canada',
  customNetworks,
  // Others
  team,
  forceAnchorLink,
  // MLB.TV Offer
  mlbtvOffer,
  // Plan Card Override
  planCardOverride,
  hasOfferInput,
  // Personalization Params
  sportId,
  leagueId,
  teamId,
}) => {
  let metaDescription =
    pageDescription ||
    '200+ live channels. Free Cloud DVR. Watch on your phone, Roku, Apple TV, Amazon Fire TV, computer & more. FOX, NBC, CBS, NFL Network, SHOWTIME, beIN SPORTS & more.'

  const params = useSelector(selectParams)
  const countryCode = useSelector(selectCountryCode)

  const [pageSlug, setPageSlug] = useState(null)
  useEffect(() => {
    if (callsign) {
      setPageSlug(callsign)
    } else if (countryCode === 'CA' || countryCode === 'CAN') {
      setPageSlug('BEINCHD')
    } else if (params?.page_slug) {
      setPageSlug(params?.page_slug)
    } else {
      setPageSlug('FS1')
    }
  }, [params?.page_slug, countryCode])

  const testVariant = useSelector(selectExperiments)?.[`CA_Plan_Bundles`]

  const sortByPlan = plan === 'soccer' && !forcePlanLength && !planCardOverride && !hasOfferInput

  const isAnchor =
    forceAnchorLink && hasPlanCards && (!sortByPlan || (sortByPlan && testVariant))
      ? '#select-plan'
      : null
  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      offerBanner={offerBanner}
      ctaHrefOverride={isAnchor}
      sportId={sportId}
      leagueId={leagueId}
      teamId={teamId}
    >
      <Helmet>
        <link rel="preconnect" crossOrigin="anonymous" href="https://preview.fubo.tv" />
        <link rel="dns-prefetch" crossOrigin="anonymous" href="https://preview.fubo.tv" />
      </Helmet>
      <SEO lang={lang} market={market} title={pageTitle || title} description={metaDescription} />
      <HeroLiveFeed
        lang={lang}
        market={market}
        callsign={pageSlug}
        title={title}
        subTitle={subtitle}
        background={background}
        ctaText={ctaText}
        subhead="Keep watching with Fubo."
        logo={logo}
        logoHeight={logoHeight}
        networkName={networkName}
        ctaHrefOverride={isAnchor}
      />
      {highlightImage && (
        <HighlightSection
          image={highlightImage}
          title={highlightHeadline}
          subtitle={highlightSubtitle}
          miceText={highlightNote}
          background={highlightBackground}
          ctaHrefOverride={isAnchor}
        />
      )}

      <BundlePlansCAControlVariant
        plan={plan}
        title={plansTitle}
        subtitle={plansSubtitle}
        offerCode={offerCode}
        soccerPlanCardImage={soccerPlanCardImage}
        premiumPlanCardImage={premiumPlanCardImage}
        ctaText={ctaText}
        forcePlanLength={forcePlanLength}
        mlbtvOffer={mlbtvOffer}
      />
      <NetworkSection
        lang={lang}
        market={market}
        networkHeadline={networkHeadline}
        networkSet={networkSet}
        customNetworks={customNetworks}
        ctaText={ctaText}
        ctaHrefOverride={isAnchor}
      />
      <ChannelSectionSimple
        lang={lang}
        market={market}
        showAllChannels={true}
        title="Your Fubo channel lineup"
      />
      <DealSection lang={lang} market={market} />
      <PlatformSection lang={lang} market={market} ctaText={ctaText} ctaHrefOverride={isAnchor} />
      <FAQSection lang={lang} market={market} team={team} />
    </Layout>
  )
}

export default CanadaLiveTemplateDefault
