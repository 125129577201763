import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectProPlanCardsFlag } from 'src/modules/reducers/cohorts'
import { selectPostalCode } from 'src/modules/reducers/market'
import { fetchRSNs } from 'src/modules/utils/checkRSN'

const useEssentialsEligibilityCheck = () => {
  const hasProPlanCards = useSelector(selectProPlanCardsFlag)
  const [isEssentialsEligible, setIsEssentialsEligible] = useState(false)
  const postalCode = useSelector(selectPostalCode)

  useEffect(() => {
    let mounted = true

    const checkEligibility = async () => {
      if (hasProPlanCards !== 'test' || !postalCode) {
        setIsEssentialsEligible(false)
        return
      }

      try {
        const rsnList = await fetchRSNs(postalCode)
        if (!mounted) return

        if (!rsnList) {
          setIsEssentialsEligible(false)
          return
        }

        const hasExcludedRSNs = ['SNY', 'MSG', 'Marquee'].some(rsn => rsnList.includes(rsn))

        if (hasExcludedRSNs) {
          console.log('not essentials eligible')
        }

        setIsEssentialsEligible(!hasExcludedRSNs)
      } catch (error) {
        console.error('Error checking RSN exclusions:', error)
        setIsEssentialsEligible(false)
      }
    }

    checkEligibility()

    return () => {
      mounted = false
    }
  }, [hasProPlanCards, postalCode])

  return isEssentialsEligible
}

export default useEssentialsEligibilityCheck
