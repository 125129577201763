// @flow

import {
  combineReducers,
  createStore,
  applyMiddleware,
  compose,
  type Store,
  type Dispatch,
} from 'redux'
import thunk from 'redux-thunk'

import type { PlanState } from './plans/'
import type { OffersState } from './offers'

import plansReducer from './plans/'
import offersReducer from './offers'
import productsReducer from './products'
import { marketReducer } from './market'
import { analyticsReducer } from './analytics'
import { paramsReducer } from './params'
import { experimentsReducer } from './experiments'
import { transcendReducer } from './transcend'
import { zvaReducer } from 'src/modules/reducers/zva'
import cohortsReducer from './cohorts/reducer'
import impactReducer from './impact/reducer'

export type AppState = {
  plans: PlanState,
  offers: OffersState,
}

export type AppStore = Store<AppState, Action>
export type AppDispatch = Dispatch<Action>

const initStore = () => {
  // ---Redux DevTools
  let composeEnhancers = compose
  if (typeof window !== 'undefined') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  }

  const middlewares = [thunk]

  const store = createStore<AppState, Action, AppDispatch>(
    combineReducers({
      plans: plansReducer,
      offers: offersReducer,
      market: marketReducer,
      analytics: analyticsReducer,
      params: paramsReducer,
      experiments: experimentsReducer,
      transcend: transcendReducer,
      zva: zvaReducer,
      cohorts: cohortsReducer,
      products: productsReducer,
      impact: impactReducer,
    }),
    composeEnhancers(applyMiddleware(...middlewares))
  )

  return store
}

export default initStore

export const selectOffersState = (state: AppState): OfferState => state.offers
export const selectPlanState = (state: AppState): PlanState => state.plans
export const selectUPState = (state: AppState): ProductsState => state.products
export const selectStateForProtobuf = state => ({
  market: state.market,
  cohorts: state.cohorts,
  analytics: state.analytics,
})
