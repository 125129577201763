// @flow

import { createSelector } from 'reselect'
import type { Plan, PlanChannel } from 'src/modules/flowTypes/domain'
import type { PlanState } from '../plans/'
import type { AppState } from '../'

type SelectPlans = (state: AppState) => Array<Plan>
type SelectChannels = (state: AppState) => Array<PlanChannel>

export const selectPlanState = (state: AppState): PlanState => state.plans

export const selectAllPlans: SelectPlans = createSelector(selectPlanState, local => local.plans)

export const selectBasicPlan: SelectPlans = createSelector(selectAllPlans, plans =>
  plans.find(plan => plan.slug === 'fubotv-basic')
)

export const selectAllChannels: SelectChannels = createSelector(
  selectAllPlans,
  (plans: Array<Plan>): Array<PlanChannel> => {
    const fubo = plans && plans.find(plan => plan.slug === 'fubotv-basic')

    if (fubo === undefined) return []
    const fuboSet = new Map(
      fubo.basic.channels.reduce((acc, channel) => {
        acc.push([channel.id, channel])
        return acc
      }, [])
    )

    for (let i = 0; i < ((fubo && fubo.addOns.length) || 0); i++) {
      const addOn = fubo && fubo.addOns[i]
      for (let j = 0; j < ((addOn && addOn.channels.length) || 0); j++) {
        const channel = addOn && addOn.channels[j]
        fuboSet && channel && !fuboSet.has(channel.id) && fuboSet.set(channel.id, channel)
      }
    }

    const channels = [...fuboSet.values()]
    return channels
  }
)

export const selectBasicChannels: SelectChannels = createSelector(
  selectAllPlans,
  (plans: Array<Plan>): Array<PlanChannel> => {
    const fubo = plans && plans.find(plan => plan.slug === 'fubotv-basic')

    if (fubo === undefined) return []
    const fuboSet = new Map(
      fubo.basic.channels.reduce((acc, channel) => {
        acc.push([channel.id, channel])
        return acc
      }, [])
    )

    const channels = [...fuboSet.values()]
    return channels
  }
)

export const selectCanadianChannels: SelectChannels = createSelector(
  selectAllPlans,
  (plans: Array<Plan>): Array<PlanChannel> => {
    const fubo = plans && plans.find(plan => plan.slug === 'fubotv-canada-premium-month')

    if (fubo === undefined) return []
    const fuboSet = new Map(
      fubo.basic.channels.reduce((acc, channel) => {
        acc.push([channel.id, channel])
        return acc
      }, [])
    )

    for (let i = 0; i < ((fubo && fubo.addOns.length) || 0); i++) {
      const addOn = fubo && fubo.addOns[i]
      for (let j = 0; j < ((addOn && addOn.channels.length) || 0); j++) {
        const channel = addOn && addOn.channels[j]
        fuboSet && channel && !fuboSet.has(channel.id) && fuboSet.set(channel.id, channel)
      }
    }

    return [...fuboSet.values()]
  }
)

export const selectCanadianEssentialsChannels: SelectChannels = createSelector(
  selectAllPlans,
  (plans: Array<Plan>): Array<PlanChannel> => {
    const fubo = plans && plans.find(plan => plan.slug === 'fubotv-canada-month')

    if (fubo === undefined) return []
    const fuboSet = new Map(
      fubo.basic.channels.reduce((acc, channel) => {
        acc.push([channel.id, channel])
        return acc
      }, [])
    )

    for (let i = 0; i < ((fubo && fubo.addOns.length) || 0); i++) {
      const addOn = fubo && fubo.addOns[i]
      for (let j = 0; j < ((addOn && addOn.channels.length) || 0); j++) {
        const channel = addOn && addOn.channels[j]
        fuboSet && channel && !fuboSet.has(channel.id) && fuboSet.set(channel.id, channel)
      }
    }

    return [...fuboSet.values()]
  }
)

export const selectCanadianEntertainmentChannels: SelectChannels = createSelector(
  selectAllPlans,
  (plans: Array<Plan>): Array<PlanChannel> => {
    const fubo = plans && plans.find(plan => plan.slug === 'fubotv-canada-ent-month')

    if (fubo === undefined) return []
    const fuboSet = new Map(
      fubo.basic.channels.reduce((acc, channel) => {
        acc.push([channel.id, channel])
        return acc
      }, [])
    )

    for (let i = 0; i < ((fubo && fubo.addOns.length) || 0); i++) {
      const addOn = fubo && fubo.addOns[i]
      for (let j = 0; j < ((addOn && addOn.channels.length) || 0); j++) {
        const channel = addOn && addOn.channels[j]
        fuboSet && channel && !fuboSet.has(channel.id) && fuboSet.set(channel.id, channel)
      }
    }

    return [...fuboSet.values()]
  }
)

export const selectSpainChannels: SelectChannels = createSelector(
  selectAllPlans,
  (plans: Array<Plan>): Array<PlanChannel> => {
    const fubo = plans && plans.find(plan => plan.slug === 'fubotv-spain')

    if (fubo === undefined) return []
    const fuboSet = new Map(
      fubo.basic.channels.reduce((acc, channel) => {
        acc.push([channel.id, channel])
        return acc
      }, [])
    )

    for (let i = 0; i < ((fubo && fubo.addOns.length) || 0); i++) {
      const addOn = fubo && fubo.addOns[i]
      for (let j = 0; j < ((addOn && addOn.channels.length) || 0); j++) {
        const channel = addOn && addOn.channels[j]
        fuboSet && channel && !fuboSet.has(channel.id) && fuboSet.set(channel.id, channel)
      }
    }

    return [...fuboSet.values()]
  }
)

export const selectLatinoChannels: SelectChannels = createSelector(
  selectAllPlans,
  (plans: Array<Plan>): Array<PlanChannel> => {
    const fubo = plans && plans.find(plan => plan.slug === 'latino-quarter')

    if (fubo === undefined) return []
    const fuboSet = new Map(
      fubo.basic.channels.reduce((acc, channel) => {
        acc.push([channel.id, channel])
        return acc
      }, [])
    )

    // for (let i = 0; i < ((fubo && fubo.addOns.length) || 0); i++) {
    //   const addOn = fubo && fubo.addOns[i]
    //   for (let j = 0; j < ((addOn && addOn.channels.length) || 0); j++) {
    //     const channel = addOn && addOn.channels[j]
    //     fuboSet &&
    //       channel &&
    //       !fuboSet.has(channel.id) &&
    //       fuboSet.set(channel.id, channel)
    //   }
    // }

    return [...fuboSet.values()]
  }
)

export const selectPromotedChannels: SelectChannels = createSelector(
  selectAllChannels,
  (allChannels: Array<PlanChannel>): Array<PlanChannel> => {
    return allChannels.filter(channel => channel.tags.promo)
  }
)
