import React from 'react'
import styled from 'styled-components'
import HeroTitle from './components/heroTitle'

import { Location } from '@reach/router'
import {
  CanadaDefaultCtaText,
  DefaultCtaText,
  defaultCTAURI,
  LatinoESDefaultCtaText,
} from 'src/modules/utils/constants'
import CTAButton from 'src/components/common/CTAButton'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import LivePreviewPlayer from 'src/components/common/LivePreviewPlayer'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'

const HeroLivePlayer = ({
  title,
  subTitle,
  ctaText,
  ctaHrefOverride,
  subhead,
  background = 'https://assets-imgx.fubo.tv/marketing/lp/images/posters/live_preview_bg-2.jpg?w=1920&ch=width&auto=format,compress',
  logo = 'https://assets-imgx.fubo.tv/marketing/lp/images/logos/Fubo_Primary_Logo_WT_RGB.png?w=300&ch=width&auto=format,compress',
  logoHeight,
  callsign,
  lang,
  networkName,
  loadingText,
  market,
}) => {
  // get ctaParams from redux
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)
  const ctaCopy = ctaText
    ? ctaText
    : lang === 'es'
    ? LatinoESDefaultCtaText
    : market === 'canada'
    ? CanadaDefaultCtaText
    : DefaultCtaText
  const handleOnClick = () => {
    fireUIInteractionEvent(
      {
        cta_name: ctaCopy,
        container_name: 'hero_section_cta_clicked',
      },
      stateForProtobuf
    )
  }

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'hero')

  return (
    <Location>
      {({ location }) => (
        <StyledHeroLivePlayer backgroundVideo={background} logoHeight={logoHeight}>
          <div className="logo-wrapper">
            <img src={logo} alt="Fubo Logo" width="100%" />
          </div>
          <div className="hero-content">
            <HeroTitle title={title} subtitle={subTitle} location={location} />
            <div className="hero-video-container">
              <LivePreviewPlayer
                ctaText={ctaText}
                ctaSubhead={subhead}
                callsign={callsign}
                lang={lang}
                networkName={networkName}
                loadingText={loadingText}
                market={market}
              />
            </div>
            <CTAButton link={ctaHrefOverride || ctaURL} onClick={handleOnClick}>
              {ctaCopy}
            </CTAButton>
          </div>
        </StyledHeroLivePlayer>
      )}
    </Location>
  )
}

export default HeroLivePlayer

const StyledHeroLivePlayer = styled.div`
  background: url(${props => props.backgroundVideo});
  background-color: #282830;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  color: #fff;
  font-size: 1.2em;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  ${'' /* border-bottom: 5px solid #fa4616; */}

  .logo-wrapper {
    position: relative;
    width: auto;
    height: 100%;
    margin: 25px 15px 15px 45px;
    box-sizing: border-box;

    img {
      max-width: 100%;
      max-height: ${props => props.logoHeight || '45px'};
      height: 100%;
      width: auto;
    }

    @media (min-width: 1385px) {
      margin-bottom: ${props => (props.logoHeight ? `calc(-${props.logoHeight} / 2)` : '-20px')};
    }

    @media (max-width: 768px) {
      text-align: center;
      max-height: ${props => props.logoHeight || '45px'};
      margin: 20px 10px 10px 20px;
    }
  }

  .hero-content {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding: 0 10px;
    box-sizing: border-box;
    .hero-video-container {
      width: 100%;
      max-width: 900px;
      position: relative;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }

  .buy-link {
    @media (max-width: 768px) {
      display: none;
    }
  }
`
