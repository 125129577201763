import React, { useEffect, useState } from 'react'
import SEO from 'src/components/common/SEO'
import FAQSection from 'src/components/common/FAQSection'
import PlatformSection from 'src/components/common/PlatformSection'
import NetworkSection from 'src/components/common/NetworkSection'
import DealSection from 'src/components/common/DealSection'
import Layout from 'src/components/common/Layout'
import ChannelSectionSimple from 'src/components/common/ChannelSectionSimple'
import HeroVideoPlayer from 'src/components/common/HeroVideoPlayer'
import { videos } from 'src/constants/videos'
import RSNGeolocationBanner from 'src/components/common/RSNOnlyBannerNew'
import { Helmet } from 'react-helmet'

const OneboxTemplateDefault = ({
  location,
  lang = 'en',
  market = 'us',
  ctaHrefOverride,
  // Offer Code
  offerCode,
  offerBanner,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  logo,
  logoHeight,
  title,
  subtitle,
  ctaText,
  video,
  videoUrlBase,
  background,
  // Network Section
  networkHeadline,
  networkSet,
  customNetworks,
  // Disable Free Trial
  disableFreeTrial = false,
  // Others
  team,
  showRSN,
  // Personalization Params
  sportId,
  leagueId,
  teamId,
}) => {
  // Disable Free Trial Modifications
  const modifiedCtaText =
    ctaText || (disableFreeTrial && (lang === 'es' ? 'Acceder Ahora' : 'Start Watching'))
  const modifiedNetworkSubtitle =
    disableFreeTrial && (lang === 'es' ? 'Cancela cuando quieras.' : 'Cancel online anytime.')

  let metaDescription =
    pageDescription ||
    '200+ live channels. Free Cloud DVR. Watch on your phone, Roku, Apple TV, Amazon Fire TV, computer & more. FOX, NBC, CBS, NFL Network, SHOWTIME, beIN SPORTS & more.'

  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      offerBanner={offerBanner}
      sportId={sportId}
      leagueId={leagueId}
      teamId={teamId}
    >
      <Helmet>
        <link rel="preconnect" crossOrigin="anonymous" href="https://marketing-partners.fubo.tv" />
        <link
          rel="dns-prefetch"
          crossOrigin="anonymous"
          href="https://marketing-partners.fubo.tv"
        />
      </Helmet>
      <SEO lang={lang} market={market} title={pageTitle || title} description={metaDescription} />
      <HeroVideoPlayer
        location={location}
        video={video || videos.generic_soccer.videoUrl}
        title={title}
        subTitle={subtitle}
        videoUrlBase={videoUrlBase}
        background={background}
        ctaText={modifiedCtaText}
        ctaHrefOverride={ctaHrefOverride}
        logo={logo}
        logoHeight={logoHeight}
      />
      {showRSN && (
        <RSNGeolocationBanner ctaText={modifiedCtaText} ctaHrefOverride={ctaHrefOverride} />
      )}
      <NetworkSection
        lang={lang}
        market={market}
        networkHeadline={networkHeadline}
        subtitle={modifiedNetworkSubtitle}
        networkSet={networkSet}
        customNetworks={customNetworks}
        ctaText={modifiedCtaText}
      />
      <ChannelSectionSimple lang={lang} market={market} />
      <DealSection lang={lang} market={market} />
      <PlatformSection lang={lang} market={market} ctaText={modifiedCtaText} />
      <FAQSection lang={lang} market={market} team={team} disableFreeTrial={disableFreeTrial} />
    </Layout>
  )
}

export default OneboxTemplateDefault
