import React from 'react'
import styled from 'styled-components'

const CompareButton = ({ href = 'https://www.fubo.tv/signup', label }) => {
  if (!label) label = 'Start your free trial'
  return (
    <StyledCompareButton href={href}>
      <span>{label}</span>
    </StyledCompareButton>
  )
}

export default CompareButton

const StyledCompareButton = styled.a`
  background: #e03100;
  border-radius: 82.8795px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6.63036px 13.2607px;
  gap: 6.63px;

  width: 150.84px;
  height: 36.47px;

  span {
    /* Label/Label-L (Bold) */

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;

    /* _/White */

    color: #ffffff;
  }
`
