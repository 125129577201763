import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { useCheckFanduelRSN } from 'src/modules/utils/useCheckFanduelRSN'

export default props => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN()

  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      // video={videos.generic_sports_no_football_no_golf.videoUrl}
      poster="https://assets.fubo.tv/marketing/lp/images/posters/fdsn_poster-1.jpg"
      title="Watch FanDuel Sports with Fubo"
      subtitle="STREAM LIVE GAMES AND LOCAL TEAM COVERAGE"
      tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
      networkSet="sports"
      customNetworks={['fanduelsports']}
      hasFanduelPlanCards={showFDPlanCards}
      showFDOnly={rsnData?.regionCode === 'FL'}
      fanduelRSNData={rsnData}
      offerBanner={showFDPlanCards && rsnData?.regionCode === 'FL' && ' '}
      additionalFAQs={additionalFAQs}
    />
  )
}

const additionalFAQs = [
  {
    question: 'Is FanDuel Sports Network the new name for Bally Sports?',
    answer:
      'Yes, Bally Sports has rebranded to FanDuel Sports Network. Fubo subscribers who previously had Bally Sports in their channel lineup can now find their local sports teams on FanDuel Sports Network.',
  },
  {
    question: 'What happened to Bally Sports on Fubo?',
    answer:
      'The company that owns Bally Sports partnered with FanDuel to rebranded the regional sports network as FanDuel Sports Network. Fubo subscribers can still access teams and sports previously available on Bally Sports under this new name.',
  },
  {
    question:
      'Will I still be able to watch my local teams on FanDuel Sports Network through Fubo?',
    answer:
      'Yes, FanDuel Sports Network will continue to broadcast local teams and games as Bally Sports did. Fubo subscribers can still enjoy the local team coverage on the new FanDuel Sports Network channel.',
  },
  {
    question:
      'Do I need a new subscription with Fubo to watch FanDuel Sports Network if I was a Bally Sports viewer?',
    answer:
      "No, if you were already watching Bally Sports on Fubo, you don't need a new subscription. The Bally Sports channels have simply been rebranded to FanDuel Sports Network, and you'll still be able to access your local team coverage.",
  },
  {
    question: 'How can I watch FanDuel Sports Network on Fubo?',
    answer:
      'FanDuel Sports Network is available through your Fubo subscription in place of Bally Sports. Check your channel lineup on Fubo to find FanDuel Sports Network or use the Fubo app to stream games.',
  },
  {
    question:
      'Will FanDuel Sports Network on Fubo show all the same sports and teams as Bally Sports?',
    answer:
      'Fubo will continue to carry the sports and teams that were carried by Bally Sports, just on the rebranded FanDuel Sports Network.',
  },
  {
    question: 'Can I still stream FanDuel Sports Network on the Fubo app?',
    answer:
      'Yes, Fubo subscribers can stream FanDuel Sports Network directly through the Fubo app, just as they did with Bally Sports.',
  },
  {
    question: 'Why did Bally Sports change to FanDuel Sports Network on Fubo?',
    answer:
      "The change is due to Bally Sports's parent company striking a partnership with FanDuel. Fubo customers can still watch their favorite local sports teams and events; only the network's name has changed.",
  },
]
