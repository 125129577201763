// @flow

import type { Plan } from 'src/modules/flowTypes/domain'

import {
    TRY_FETCH_OFFERS,
    FETCH_OFFERS_SUCCESS,
    FETCH_OFFERS_FAILURE,
} from './actionTypes'

import type { OffersAction } from './actionTypes'

export type OfferState = {
  offers: Array<Plan>,
  isFetchPending: boolean,
  isError: boolean,
  error: null,
}

const INITIAL_STATE = {
  offers: [],
  isFetchPending: false,
  isError: false,
  error: null,
}

export default function reducer(state: OfferState = INITIAL_STATE, action: OffersAction = {}) {
  switch (action.type) {
    case TRY_FETCH_OFFERS:
      return {
        ...state,
        isFetchPending: true,
      }

    case FETCH_OFFERS_SUCCESS:
     return {
       ...state,
       offers: action.payload,
       isFetchPending: false,
     }

     case FETCH_OFFERS_FAILURE:
        return {
          ...state,
          error: action.payload,
          isError: true,
          isFetchPending: false,
        };

    default:
      return state
    }
  }