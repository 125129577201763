import React, { useEffect, useState } from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      <CanadaTemplateDefault
        location={props.location}
        lang="en"
        market="canada"
        video={videos.seasonal_ca.videoUrl}
        videoMobile={videos.seasonal_ca.videoMobileUrl}
        annotations={videos.seasonal_ca.annotations}
        title="Watch live sports & entertainment"
        subtitle="ENJOY TOP LEAGUES, POPULAR TV SHOWS & MORE"
        tagline="Stream it all from your TV, tablet & other devices"
        networkSet="canadaEssentials"
        plan="soccer"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div``
