import React from 'react'
import SEO from 'src/components/common/SEO'
import FAQSection from 'src/components/common/FAQSection'
import PlatformSection from 'src/components/common/PlatformSection'
import NetworkSection from 'src/components/common/NetworkSection'
import DealSection from 'src/components/common/DealSection'
import Layout from 'src/components/common/Layout'
import ChannelSectionSimple from 'src/components/common/ChannelSectionSimple'
import HeroVideoPlayer from 'src/components/common/HeroVideoPlayer'
import { videos } from 'src/constants/videos'
import BundlePlansCAControlVariant from 'src/components/common/BundlePlansCAByDuration'
import { Helmet } from 'react-helmet'
import { CanadaDefaultCtaText } from 'src/modules/utils/constants'

const CanadaOneboxTemplateDefault = ({
  location,
  lang = 'en',
  market = 'canada',
  ctaHrefOverride,
  ctaText,
  // Offer Code
  offerCode,
  offerBanner,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  logo,
  logoHeight,
  title,
  subtitle,
  video,
  videoUrlBase,
  background,
  // Plan Cards
  hasPlanCards = true,
  plan,
  plansTitle,
  plansSubtitle,
  // Network Section
  networkHeadline,
  networkSet = 'canada',
  customNetworks,
  forcePlanLength,
  planCardOverride,
  hasOfferInput,
  soccerPlanCardImage,
  premiumPlanCardImage,
  // Personalization Params
  sportId,
  leagueId,
  teamId,
  // Others
  team,
}) => {
  let metaDescription =
    pageDescription ||
    '200+ live channels. Free Cloud DVR. Watch on your phone, Roku, Apple TV, Amazon Fire TV, computer & more. FOX, NBC, CBS, NFL Network, SHOWTIME, beIN SPORTS & more.'
  const sortByPlan = plan === 'soccer' && !forcePlanLength && !planCardOverride && !hasOfferInput
  let DefaultCtaText = CanadaDefaultCtaText
  if (ctaText) {
    DefaultCtaText = ctaText
  }
  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      offerBanner={offerBanner}
      sportId={sportId}
      leagueId={leagueId}
      teamId={teamId}
    >
      <Helmet>
        <link rel="preconnect" crossOrigin="anonymous" href="https://marketing-partners.fubo.tv" />
        <link
          rel="dns-prefetch"
          crossOrigin="anonymous"
          href="https://marketing-partners.fubo.tv"
        />
      </Helmet>
      <SEO lang={lang} market={market} title={pageTitle || title} description={metaDescription} />
      <HeroVideoPlayer
        location={location}
        video={video || videos.generic_soccer.videoUrl}
        title={title}
        subTitle={subtitle}
        videoUrlBase={videoUrlBase}
        background={background}
        ctaText={ctaText}
        ctaHrefOverride={ctaHrefOverride}
        logo={logo}
        logoHeight={logoHeight}
      />

      <BundlePlansCAControlVariant plan={plan} title={plansTitle} subtitle={plansSubtitle} />

      <NetworkSection
        lang={lang}
        market={market}
        networkHeadline={networkHeadline}
        networkSet={networkSet}
        customNetworks={customNetworks}
      />
      <ChannelSectionSimple
        lang={lang}
        market={market}
        showAllChannels={true}
        title="Your Fubo channel lineup"
      />
      <DealSection lang={lang} market={market} />
      <PlatformSection lang={lang} market={market} />
      <FAQSection lang={lang} market={market} team={team} />
    </Layout>
  )
}

export default CanadaOneboxTemplateDefault
