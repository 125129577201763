import { getPlans, getPlansByLocation } from '@fubotv/js-client/dist/esm/domains/plan'

import { call } from '../js-client'

import { FETCH_PLANS, FETCH_PLANS_SUCCESS, FETCH_PLANS_FAILURE } from './actionTypes'

const fetchPlans = () => {
  return async dispatch => {
    try {
      const data = await call(getPlans)
      await dispatch(fetchPlansSuccess(data))
    } catch (error) {
      if (error.response) {
        dispatch(fetchPlansFailure(error.response))
      } else if (error.request) {
        dispatch(fetchPlansFailure(error.request))
      } else {
        dispatch(fetchPlansFailure(error.message))
      }
    }
  }
}

const fetchPlansByPostalCode = ({ postal, countrySlug }) => {
  return async dispatch => {
    try {
      const data = await call(getPlansByLocation, {
        homePostalCode: postal,
        currentPostalCode: postal,
        countrySlug: countrySlug,
      })
      await dispatch(fetchPlansSuccess(data))
    } catch (error) {
      if (error.response) {
        dispatch(fetchPlansFailure(error.response))
      } else if (error.request) {
        dispatch(fetchPlansFailure(error.request))
      } else {
        dispatch(fetchPlansFailure(error.message))
      }
    }
  }
}

const fetchPlansSuccess = plans => {
  return {
    type: FETCH_PLANS_SUCCESS,
    payload: plans,
  }
}

const fetchPlansFailure = error => {
  return {
    type: FETCH_PLANS_FAILURE,
    payload: error,
  }
}

export { fetchPlans, fetchPlansByPostalCode, fetchPlansSuccess, fetchPlansFailure }
