import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PlanCardWithImage from 'src/components/common/PlanCardWithImage'
import styled from 'styled-components'
import { selectBasicChannels, selectBasicPlan } from 'src/modules/reducers/plans/selectors'
import { usPlanData } from 'src/constants/usPlanData'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { fireUIRenderEvent } from 'src/modules/analytics/v3'

const ProPlanCards = ({
  title = 'Which plan is right for you?',
  subtitle = 'Start your free trial. No commitment. Cancel anytime.',
}) => {
  const selectPlan = useSelector(selectBasicPlan)
  const proChannels = useSelector(selectBasicChannels)
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const [isEventSent, setIsEventSent] = useState(false)

  const findChannelsByAddon = (plan, code) => {
    const foundAddon = plan?.addOns?.find(addon => addon.code === code)
    if (foundAddon) return foundAddon.channels
    else return []
  }
  const includesSportsPlus = findChannelsByAddon(selectPlan, 'deportes')
  const showTimeChannelCount = findChannelsByAddon(selectPlan, 'showtime')
  const fuboExtraChannels = findChannelsByAddon(selectPlan, 'fubo-extra-v2')
  const newsPlusChannels = findChannelsByAddon(selectPlan, 'news-plus')

  const eliteChannels = [
    ...proChannels,
    ...fuboExtraChannels,
    ...newsPlusChannels,
    ...includesSportsPlus,
  ]
  const ultimateChannels = [
    ...proChannels,
    ...fuboExtraChannels,
    ...newsPlusChannels,
    ...includesSportsPlus,
    ...showTimeChannelCount,
  ]

  const channels = {
    pro: proChannels.length,
    elite: eliteChannels.length,
    ultimate: ultimateChannels.length,
  }

  const planData = usPlanData(channels)

  const createPlanCard = (data, index) => {
    return (
      <PlanCardWithImage
        key={index}
        promoImg={data.promoImg}
        plan={data.plan}
        title={data.title}
        subtitle={data.subtitle}
        price={Math.round(data.price * 1e2) / 1e2}
        discountedPrice={Math.round(data.discountedPrice * 1e2) / 1e2}
        savingsNote={data.savingsNote}
        listItems={data.listItems}
        offerCode={data.offerCode}
        asterisk={data.asterisk}
        footnote={data.footnote}
        networkLogos={data.networkLogos}
        // ctaText={ctaText || data.ctaText}
        ctaText="Start your free trial"
      />
    )
  }

  useEffect(() => {
    if (!isEventSent) {
      fireUIRenderEvent(
        {
          component: 'pro_plan_cards',
        },
        stateForProtobuf
      )
      setIsEventSent(true)
    }
  }, [isEventSent])

  return (
    <StyledBundleSection id="select-plan">
      <div className="section-header">
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      <div className="section-content">
        {createPlanCard(planData.essential, 0)}
        {createPlanCard(planData.pro, 1)}
      </div>
    </StyledBundleSection>
  )
}

export default ProPlanCards

const StyledBundleSection = styled.section`
  color: #fff;
  padding: 60px 20px 60px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #19273c;
  background-image: null;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .inline-block {
    display: inline-block;
  }

  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  #select-plan {
    position: absolute;
    top: -80px;
  }

  .section-header {
    padding: 0 0 30px 0;

    h2 {
      color: rgb(240, 241, 242);
      font-size: 40px;
      font-weight: 700;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      letter-spacing: 1px;
      line-height: 44px;

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 21px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;
      line-height: 23px;
      margin-top: 20px;
    }
  }

  .section-tabs {
    width: 100%;
    max-width: 500px;
    .plan-tabs {
      overflow: hidden;
      margin-bottom: 30px;
      display: flex;
      width: 100%;
      max-width: 500px;
      align-items: flex-end;
      > * {
        flex: 1 0 0;
      }
      .tab {
        position: relative;
      }
      .savings {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .tab:first-child {
        button {
          border-radius: 10px 0 0 10px;
        }
      }
      .tab:last-child {
        button {
          border-radius: 0 10px 10px 0;
        }
      }
      button {
        width: 100%;
        ${'' /* Reset Button CSS */}
        border: none;
        margin: 0;
        padding: 15px 0;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        cursor: pointer;
        background: #ffffff;
        color: #000;

        &:hover {
          background: #f0f0f0;
        }

        &.active {
          ${'' /* background: #e6e6e6; */}
          background: #000;
          color: #fff;
        }

        .tab-content {
          ${'' /* padding: 15px 18px; */}
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          -webkit-letter-spacing: -0.17px;
          -moz-letter-spacing: -0.17px;
          -ms-letter-spacing: -0.17px;
          letter-spacing: -0.17px;
          line-height: 20px;
          text-transform: uppercase;
          @media (max-width: 600px) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 1020px) {
      align-items: stretch;
      flex-direction: row;
      gap: 30px;
      margin-bottom: 50px;
    }
  }

  .section-footnote {
    color: #c2c2c2;
    text-align: center;

    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 22.44px */
    max-width: 530px;
  }

  .planCard-container {
    ${'' /* max-width: 400px; */}
    .planCard {
      background: #040911;
      padding-top: 37%;
      .planCard__image {
        &::after {
          background: radial-gradient(200% 66% at 127% 5%, transparent 48%, rgb(4, 9, 17));
        }
      }
      .planCard__price {
        margin-top: 33px;
      }
      .planCard__list {
        margin-top: 40px;
        max-width: 340px;
        .list-item {
          font-size: 18px;
        }
      }
    }
  }
`
