import { createSelector } from 'reselect'


import {
  WEB_MARKETING_FAQ_MESSAGING,
  WEB_MARKETING_PRO_PLAN_CARDS,
} from './constants'
import { StacFlags } from './flags'

import type { BooleanSelector, StacFlagName, StringSelector } from './types'


export type StacFlagsState = {
  isDefault: boolean
  isInitialized: boolean
  isFetching: boolean
  isRefetching: boolean
  flags: StacFlags
}

export const selectStacFlagsState = (state): StacFlagsState => state.cohorts


export const selectStacFlagsReady: (state) => boolean =
  createSelector(
    selectStacFlagsState,
    ({ isInitialized, isFetching }) => isInitialized && !isFetching
  )

export const selectAllStacFlags: (arg1) => StacFlags = createSelector(
  selectStacFlagsState,
  (local) => local.flags
)

// allows passing of flagName and sets it as a cacheKey
const selectStacFlagName = <T>(_: T, flagName: StacFlagName): StacFlagName =>
  flagName

// @ts-expect-error
export const selectStacFlagValue: <T>(
  arg1,
  arg2: StacFlagName
) => T | null = createSelector(
  selectAllStacFlags,
  selectStacFlagName,
  (flags: StacFlags, flagName: StacFlagName) => {
    const flag = flags[flagName]
    return flag ? flag.value : null
  }
)


export const selectFAQMessagingFlag: StringSelector = (state) =>
  selectStacFlagValue(state, WEB_MARKETING_FAQ_MESSAGING)

export const selectProPlanCardsFlag: StringSelector = (state) =>
  selectStacFlagValue(state, WEB_MARKETING_PRO_PLAN_CARDS)
