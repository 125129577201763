import { useSelector } from 'react-redux'
import {
  selectAllChannels,
  selectCanadianChannels,
  selectLatinoChannels,
  selectSpainChannels,
} from 'src/modules/reducers/plans/selectors'

function useSelectChannels(market) {
  const usChannels = useSelector(selectAllChannels)
  const canadianChannels = useSelector(selectCanadianChannels)
  const spainChannels = useSelector(selectSpainChannels)
  const latinoChannels = useSelector(selectLatinoChannels)

  switch (market) {
    case 'us':
      return usChannels
    case 'canada':
      return canadianChannels
    case 'es':
      return spainChannels
    case 'latino':
      return latinoChannels
    case 'spain':
      return spainChannels
    default:
      return usChannels
  }
}

export default useSelectChannels
