const TRY_FETCH_OFFERS = 'offers/FETCH_OFFERS'
const FETCH_OFFERS_SUCCESS = 'offers/FETCH_OFFERS_SUCCESS'
const FETCH_OFFERS_FAILURE = 'offers/FETCH_OFFERS_FAILURE'
const OVERRIDE_OFFERS = 'offers/OVERRIDE_OFFERS'


export {
    TRY_FETCH_OFFERS,
    FETCH_OFFERS_SUCCESS,
    FETCH_OFFERS_FAILURE,
    OVERRIDE_OFFERS,
}