import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'
import { useCheckFanduelRSN } from 'src/modules/utils/useCheckFanduelRSN'

const BallySportsNBA = props => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN()
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      //nba league pass addon
      // addonCode="nbalp"
      video={props.video || videos.generic_basketball.videoUrl}
      staticAnnotation={props.staticAnnotation}
      title={props.title}
      subtitle={props.subtitle}
      tagline={props.tagline || 'Anytime. Anywhere. From any device. Regional restrictions apply.'}
      networkSet="basketball"
      customNetworks={props.customNetworks || ['fanduelsports']}
      sportId={sportIds.Basketball}
      leagueId={leagueIds.NBA}
      hasFanduelPlanCards={showFDPlanCards}
      fanduelRSNData={rsnData}
      showFDOnly={props.showFDOnly}
      {...props}
    />
  )
}
export default BallySportsNBA
