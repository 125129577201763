const FETCH_PLANS = "plans/FETCH_PLANS"
const FETCH_PLANS_SUCCESS = "plans/FETCH_PLANS_SUCCESS"
const FETCH_PLANS_FAILURE = "plans/FETCH_PLANS_FAILURE"

export { FETCH_PLANS, FETCH_PLANS_SUCCESS, FETCH_PLANS_FAILURE }

export type OffersAction =
  | { type: typeof FETCH_PLANS }
  | { type: typeof FETCH_PLANS_SUCCESS, payload: Array<Plan> }
  | { type: typeof FETCH_PLANS_FAILURE, payload: Error }
